@mixin button-shadow-variant($color-focus-var, $color-active-var: $color-focus-var) {
  &:focus,
  .focus {
    box-shadow: $focus-shadow-options $color-focus-var;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    box-shadow: $focus-shadow-options $color-focus-var;
  }

  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: $focus-shadow-options $color-active-var;
  }
}

@mixin button-active-variant($color-var, $color-bg-var, $color-focus-var: $color-var, $color-focus-bg-var: $color-bg-var) {
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle,
  &:not(:disabled):not(.disabled):active:hover,
  &:not(:disabled):not(.disabled).active:hover,
  .show > &.dropdown-toggle:hover {
    color: $color-var;
    background: $color-bg-var;
  }

  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  .show > &.dropdown-toggle:focus {
    color: $color-focus-var;
    background: $color-focus-bg-var;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin line-clamp($num-lines) {
  display: -webkit-box;
  -webkit-line-clamp: $num-lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin spread-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}

@function t-var($key, $map: $theme-map-main, $fallback: false) {
  @if not map-has-key($map, $key) {
    @error "key: '#{$key}', is not a key in map: #{$map}";
  }
  @if ($fallback) {
    @return var($key, map-get($theme-map-main, $key));
  } @else {
    @return var($key);
  }
}
