.app-header {
  background: var(--header-bg-sm, $header-bg-sm);
  color: var(--header-color-sm, $header-color-sm);
  width: 100%;
  height: $navbar-height;
  flex: 0 0 $navbar-height;
  display: flex;
  align-items: center;

  @extend %layout-transition;

  &--fixed {
    position: sticky;
    top: 0;
    z-index: $zindex-sticky;

    //@include media-breakpoint-up(lg) {
    //  width: calc(100% - var(--sidebar-width));
    //}
  }

  @include media-breakpoint-up(lg) {
    background: var(--header-bg, $header-bg);
    color: var(--header-color, $header-color);
  }

  &__body {
    width: 100%;
    height: $navbar-height;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(lg) {
      width: auto;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--header-line-color, $header-line-color);
    }
  }

  .navbar-brand {
    position: static;
    left: 0;
    margin-left: 0;
    flex: 1 1;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &__link {
      display: inline-block;
      color: var(--header-color-sm, $header-color-sm);
      text-decoration: none;
      padding-bottom: 2px;
    }

    &__logo {
      max-width: 100%;
    }
  }
}

.notif-icon {
  display: inline-block;
  position: relative;
}

