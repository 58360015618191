.info-card-menu-section {
  display: flex;
  gap: 32px;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    gap: 48px;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  &__main {
    @include media-breakpoint-up(md) {
      flex: 1;
    }

    @include media-breakpoint-up(lg) {
      grid-row-gap: 16px;
      grid-column-gap: 48px;
    }
  }

  &__menu {
    padding: 0;
    margin: 0 0 16px;
    min-width: 230px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      order: 2;
    }
  }

  .menu-actions {
    list-style: none;
    padding: 0;
    margin: 0 0 16px;
    min-width: 230px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      margin-left: 32px;
      order: 2;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 48px;
    }

    &__item {
      border-bottom: 1px solid var(--line-color-main);

      &:last-child {
        border-bottom: 0;
      }
    }

    .action-link {
      font-family: var(--font-heading, $font-heading);
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: .5px;
      text-transform: uppercase;
      padding: 11px 0 11px 8px;
      display: flex;
      align-items: flex-start;
      color: inherit;

      &:hover {
        background-color: var(--secondary-bg);
        text-decoration: none;
        color: inherit;
      }

      @include media-breakpoint-up(md) {
        padding-left: 16px;
      }

      &__icon {
        margin-right: 8px;

        .svg-icon {
          display: block;
        }
      }

      &__text {
        align-self: center;
      }

      //&.action-secondary {
      //  color: var(--secondary);
      //}
      //&.action-muted {
      //  color: var(--text-shaded);
      //}
      //&.action-warning {
      //  color: var(--warning);
      //}
    }
  }
}

.admin-section {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0px;
  }

  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    border-bottom: 1px solid var(--line-color-main);
    padding-bottom: 8px;
    margin-bottom: 12px;

    &--uppercase {
      font-family: var(--font-heading, $font-heading);
      font-size: 18px;
      line-height: 21px;
      letter-spacing: .5px;
      text-transform: uppercase;
    }
  }
}

.fi-card,
.fi-tab {
  &__header {
    border-bottom: 1px solid var(--form-section-border-color);
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
  }

  &__body {
    margin-bottom: 30px;
  }

  &__footer {
    padding-top: 20px;
    border-top: 1px solid var(--form-section-border-color);
    display: flex;

    .footer-buttons {
      display: flex;
      flex-wrap: wrap;
      margin-left: auto;
      margin-bottom: -10px;
      margin-right: -10px;

      .footer-button {
        //width: 100%;
        min-width: 140px;
        margin-right: 10px;
        margin-bottom: 10px;

        @include media-breakpoint-up(sm) {
          width: calc(50% - 10px);
        }

        @include media-breakpoint-up(md) {
          width: auto;
        }
      }
    }
  }
}

.fi-edit {
  .fl {
    max-width: 450px;
  }

  .form-section:last-child {
    border-bottom: none;
  }
}

.custom-switch {
  &--enabler .custom-control-label {
    background-color: var(--secondary-bg);
  }

  &--enabler .custom-control-input:checked ~ .custom-control-label {
    background-color: var(--active-bg);
    color: var(--switcher-enabler-active-color);
  }
}

.label-with-link {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  line-height: 17px;

  &__label:not(.custom-control-label):not(.form-check-label):not(.btn) {
    padding-right: 5px;
    margin-bottom: 0;
    margin-right: auto;
  }
}

.country-badges {
  list-style: none;
  margin: 0 0 -4px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  &__item {
    margin-right: 2px;
    line-height: 18px;
    border-radius: 10px;
    background-color: var(--text-muted);
    font-weight: bold;
    font-size: 12px;
    font-family: var(--font-heading, $font-heading);
    color: #fff;
    text-transform: uppercase;
    padding: 0 5px;
    margin-bottom: 4px;
  }
}

.form-buttons {
  display: flex;
  margin: 32px 15px;

  &__secondary {
    margin-right: 12px;
  }

  &__main {
    margin-left: auto;
    display: flex;

    .btn {
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.info-card-section {
  margin-top: 32px;

  @include media-breakpoint-up(lg) {
    margin-top: 48px;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--line-color-main);
    padding: 0 0 10px 0;

    &--without-border {
      border: none;
    }
  }
}

.info-card-columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-row-gap: 16px;
  grid-column-gap: 48px;
}

.info-card-additional-section {
  &:not(:first-child) {
    margin-top: 20px;
    padding-top: 14px;
    border-top: 1px solid var(--line-color-main);
  }
}
