@import '~@/assets/scss/common/placeholders';

.p-company-role {
  &__header {
    margin-bottom: 8px;
  }

  .field-company-title {
    .field-title__text {
      @extend %body-2;

      font-weight: 700;
    }

    .field-title__subtitle {
      margin: 0;
      color: var(--text-color);

      .org-number-label {
        font-weight: normal;
      }
    }
  }
}

.pc-list-contacts {
  margin: 0 0 16px;
  padding: 0;
  list-style: none;

  &:last-child {
    margin-bottom: 0;
  }

  &__item {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__person {
    font-weight: 500;
    margin-bottom: 4px;

    .v-tooltip {
      display: inline-block;
    }
  }

  &__person &__title {
    display: inline-block;
    margin-right: 5px;

    .field-title__text {
      @extend %body-2;

      font-weight: 500;
    }
  }

  &__roles {
    margin-bottom: 4px;
  }

  &__contact {
    display: flex;
    align-items: flex-start;

    .svg-icon {
      display: block;
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      margin-top: -1px;
      margin-right: 8px;
    }

    .pcc-phone {
      min-width: 0;
      flex: 1;
      align-self: center;
      margin-right: -4px;

      span {
        display: inline-block;
        margin-right: 4px;

        &::after {
          content: ";";
        }

        &:last-child::after {
          content: normal;
        }
      }
    }
  }
}
