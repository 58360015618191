:root {
  --bar-size: 16px;
  --bar-border-width-vertical: 0 5px;
  --bar-border-width-horizontal: 5px 0;
  --bar-border-radius-vertical: 10px / 5px;
  --bar-border-radius-horizontal: 5px / 10px;
  --bar-border-color: var(--main-bg);
}

.modal div,
.app-content div,
.popover-body div,
ul,
fieldset {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: var(--bar-size);
  }

  &::-webkit-scrollbar:horizontal {
    height: var(--bar-size);
  }

  &::-webkit-scrollbar-thumb {
    //border-radius: calc(var(--bar-size) / 2);
    background-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color);
    border: solid var(--bar-border-color);
  }

  &::-webkit-scrollbar-track {
    //border-radius: calc(var(--bar-size) / 2);
    background-color: var(--scrollbar-track-color, $scrollbar-track-color);
    border: solid var(--bar-border-color);
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    &:horizontal {
      border-width: var(--bar-border-width-horizontal);
      border-radius: var(--bar-border-radius-horizontal);
    }

    &:vertical {
      border-width: var(--bar-border-width-vertical);
      border-radius: var(--bar-border-radius-vertical);
    }
  }
}

//.data-table {
//  --bar-size: 16px;
//  --bar-border-width: 5px 0;
//}
