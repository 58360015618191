@charset "UTF-8";

// .env-mode-3 .cov.cov-position-container,
// .env-mode-3 #_hj_feedback_container {
//   visibility: hidden;
// }

@property --icon-color-1 {
  syntax: "<color>";
  initial-value: currentColor;
  inherits: true;
}

@property --icon-color-2 {
  syntax: "<color>";
  initial-value: transparent;
  inherits: true;
}

@property --icon-color-3 {
  syntax: "<color>";
  initial-value: transparent;
  inherits: true;
}

:root {
  --sidebar-with-normal: #{$sidebar-width};
  --sidebar-with-minimized: #{$sidebar-minimized-width};
  --sidebar-width: var(--sidebar-with-normal);
  --animation-duration: .25s;
}

.cov-root {
  z-index: 10000 !important;

  .is-mobile & {
    display: none;
  }

  .cov {
    &.cov-position-container {
      // top: auto;
      // height: 0;
      right: 10px;
      // left: 15px;
      bottom: 15px;
    }

    &.cov-chat-button {
      width: 40px;
      height: 40px;

      // @include media-breakpoint-up(lg) {
      //   position: fixed;
      //   right: auto;
      //   left: 15px;
      //   bottom: 15px;
      // }
    }

    &.cov-launcher-icon {
      background-size: 100%;
    }

    &.cov-launcher-icon-chat-dot {
      top: calc(50% - 2px);
    }

    &.cov-launcher-icon-chat-dot1 {
      left: calc(35% - 2px);
    }

    &.cov-launcher-icon-chat-dot2 {
      left: calc(50% - 2px);
    }

    &.cov-launcher-icon-chat-dot3 {
      left: calc(65% - 2px);
    }

    &.cov-chat-panel {
      bottom: 60px;
    }
  }
}

button:focus {
  outline: none;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  .app-header>&,
  .main>& {
    .container-full-height & {
      height: 100%;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: var(--font-heading, $font-heading);
  font-weight: normal;
}

h1, .h1 {
  font-size: 32px;
  line-height: 38px;

  @include media-breakpoint-up(lg) {
    font-size: 34px;
    line-height: 40px;
  }
}

h2, .h2 {
  font-size: 28px;
  line-height: 33px;
}

h3, .h3 {
  font-size: 22px;
  line-height: 26px;
}

h4, .h4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

h5, .h5 {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

h6, .h6 {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
}

a {
  color: var(--link-color, $link-color);

  &:hover {
    color: var(--link-hover-color, $link-hover-color);
  }
}

a,
button {
  transition: .3s ease-in-out;
  transition-property: color, background-color, opacity;
}

[role="button"]:focus {
  outline: none;
}

.i-block {
  display: inline-block;
}

.help-text {
  font-size: 12px;
  line-height: 14px;
  color: var(--text-shaded, $text-shaded);
  margin-bottom: 0;
  margin-top: 5px;
  display: flex;

  &__icon {
    margin-right: 8px;

    .svg-icon {
      width: 16px;
      height: 16px;
      display: block;
    }
  }

  .search-form__body & {
    padding: 0 5px;
    margin-bottom: 15px;
    margin-top: 0;
  }
}

.text-muted {
  color: var(--text-muted);
}

.text-shaded {
  color: var(--text-shaded);
}

.text-error {
  color: var(--danger);
}

.text-warning {
  color: var(--warning, $warning);
}

.text-secondary {
  color: var(--secondary, $secondary);
}

.text-unselectable,
.c-email-text {
  user-select: none;
}

.svg-icon {
  &--eco {
    color: var(--eco, $eco);
  }

  &--activity-overdue {
    color: var(--warning, $warning);
  }

  &--activity-clock {

    .planned-today &,
    &.planned-today {
      color: var(--success, $success);
    }

    .planned-later &,
    &.planned-later {
      color: var(--text-color, $text-color);
    }
  }

  &--perfect {
    color: var(--success, $success);
  }

  &--uncertainty {
    color: var(--warning, $warning);
  }
}

.clamp-2 {
  @include line-clamp(2);

  .btn &,
  .search-form__action--link & {
    margin-top: -5px;
    padding-top: 5px;
  }
}

.donut-stages {
  path {
    fill: var(--donut-stages-fill, $donut-stages-fill);
  }

  &--1 {
    path:nth-child(1) {
      fill: var(--donut-stages-active-fill, $donut-stages-active-fill);
    }
  }

  &--2 {

    path:nth-child(1),
    path:nth-child(2) {
      fill: var(--donut-stages-active-fill, $donut-stages-active-fill);
    }
  }

  &--3 {

    path:nth-child(1),
    path:nth-child(2),
    path:nth-child(3) {
      fill: var(--donut-stages-active-fill, $donut-stages-active-fill);
    }
  }

  &--4 {

    path:nth-child(1),
    path:nth-child(2),
    path:nth-child(3),
    path:nth-child(4) {
      fill: var(--donut-stages-active-fill, $donut-stages-active-fill);
    }
  }
}

.progress {
  background-color: var(--progress-pages-bg, $progress-pages-bg);
}

.progress-bar {
  height: 100%;
  background-color: var(--progress-pages-active-bg, $progress-pages-active-bg);
}

.nav-pills {

  .nav-link.active,
  .show>.nav-link {
    color: var(--text-inverse-color, $text-inverse-color);
    background-color: var(--primary, $primary);
  }
}

.popover {
  border-color: var(--line-color-dark);
  filter: drop-shadow(0 2px 2px var(--shadow-main));

  &--height-limited .popover-body {
    max-height: 250px;
    overflow-y: auto;
    margin: 16px 4px 16px 0;
    padding: 0 12px 0 16px;
  }
}

.page-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 24px;
  }

  &__title {
    flex: 1 1 0;

    h1 {
      margin-bottom: 0;
    }
  }

  &__trf-settings {
    margin-left: 15px;
  }
}

.page-inner-header {
  margin-bottom: 20px;
}

.page-title {
  vertical-align: middle;
  margin-bottom: 10px;

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: flex-start;
  }

  &__main {
    min-width: 0;
    margin-right: auto;
    padding-right: 16px;
    margin-top: -8px;
  }

  &__nav,
  &__avatar {
    vertical-align: 30%;
    display: inline-flex;
    align-items: center;
  }

  &__avatar .c-avatar__img--image {
    display: inline;
    margin-bottom: 2px;
  }

  &__nav .entity-navigation__nav {
    margin-right: 15px;
  }

  &__avatar {
    margin-right: 8px;

    .c-avatar {
      --size: 40px;
    }
  }

  &__text {
    display: inline-block;

    @include media-breakpoint-up(md) {
      font-size: 34px;
      line-height: 40px;
    }

    .tender-page__title {
      display: inline;
    }
  }

  &__nav,
  &__text {
    margin-top: 8px;
  }

  &__buttons {
    display: flex;
    flex: none;
    padding-bottom: 8px;

    & .btn+.btn {
      margin-left: 2px;
    }

    .btn-link {
      display: flex;
      font-size: 14px;
      line-height: 22px;
      font-weight: bold;
      text-transform: uppercase;
      font-family: var(--font-heading, $font-heading);
      letter-spacing: 1px;

      &__icon {
        margin-right: 8px;
        display: block;

        .svg-icon {
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  &__info {
    margin-top: 10px;

    @include media-breakpoint-up(md) {
      margin: 0 0 0 16px;
    }
  }
}

.link-trf-settings {
  color: var(--stage-chevron-icon-color, $stage-chevron-icon-color);
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    color: var(--triggers-filter-action-link-hover-color, $triggers-filter-action-link-hover-color);
    text-decoration: none;
  }

  &__text {
    display: none;
    font-family: var(--font-heading, $font-heading);
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      display: block;
      margin-left: 7px;
    }
  }

  .svg-icon {
    display: block;
    width: 30px;
    height: 30px;
    margin-top: -2px;

    @include media-breakpoint-up(lg) {
      width: 20px;
      height: 20px;
    }
  }
}

.page-header-i-wrap {

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: flex-start;
  }
}

.page-header-color-tags {
  margin-right: -4px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(md) {
    margin-left: auto;
    margin-bottom: 0;
    margin-right: 0;
    order: 2;
  }

  .color-tag {
    margin-right: 4px;
    margin-bottom: 4px;
  }
}

.page-header-info {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    font-size: 14px;
    line-height: 18px;
    min-width: 0;
    flex: 1;
  }

  &__list {
    padding: 0;
    margin: 0 -40px -10px 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin-right: 40px;
    margin-bottom: 10px;
  }

  &__label {
    font-weight: bold;
    color: var(--text-shaded, $text-shaded);
  }

  .link-to-map {
    display: inline;

    .svg-icon {
      margin-left: 5px;
    }
  }
}

.page-header-color-tags+.page-header-info {
  margin-right: 16px;
}

.tabs-wrapper {

  &,
  &.card {
    background: none;
    border: 0;
  }
}

.tabs>.card-header {
  background: none;
  border: 0;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.nav-tabs {
  border-bottom: 0;
  align-items: flex-end;

  .card-header & {
    margin: (-$card-spacer-y) (-$card-spacer-x);
    flex-wrap: nowrap;
  }

  .ca-stages-tabs & {
    margin-bottom: -2px;
  }

  .nav-item {
    margin-right: 2px;
    margin-bottom: 2px;

    .ca-stages-tabs & {
      margin-right: 6px;
      flex: 1;
    }

    .tabs--main>.tabs-nav-wrapper & {
      min-width: max-content;
      max-width: 180px;
      flex: 1;
    }

    .tabs--auto-width>.tabs-nav-wrapper & {
      max-width: none;
    }

    @include media-breakpoint-up(md) {
      // min-width: 150px;
      min-width: 90px;

      .projects-search & {
        // min-width: 130px;
      }

      &.unimportant {
        min-width: 0;
      }
    }
  }

  .nav-link {
    position: relative;
    width: 100%;
    z-index: 0;
    color: var(--tab-link-color, $tab-link-color);
    //background:  var(--tab-link-bg, $tab-link-bg);
    border: 1px solid var(--tab-link-border-color, $tab-link-border-color);
    // border-bottom: 0;
    border-radius: 4px 4px 0 0;
    padding: 0;
    font-family: var(--font-heading, $font-heading);
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    text-align: center;
    // box-shadow: var(--tab-link-shadow, $tab-link-shadow);

    @include media-breakpoint-up(md) {
      min-width: 150px;
      max-width: 100%;

      .tabs--main>.tabs-nav-wrapper & {
        min-width: max-content;
      }
    }

    .projects-search & {
      letter-spacing: 1px;
    }

    .tabs--main &,
    .card-header & {
      color: var(--tab-link-color, $tab-link-color);
      padding: 0;
      border-top: 1px solid var(--tab-link-border-color, $tab-link-border-color);
    }

    .card-header &.disabled {
      border-color: var(--tab-link-border-color, $tab-link-border-color);
    }

    &:hover,
    &:focus {
      border-color: var(--tab-link-border-color, $tab-link-border-color);
      color: var(--tab-link-hover-color, $tab-link-hover-color);
    }

    .tabs--main>.tabs-nav-wrapper &.active,
    .card-header &.active {
      z-index: 2;
      color: var(--tab-link-active-color, $tab-link-active-color);
      background: var(--tab-link-active-bg, $tab-link-active-bg);
      border-color: var(--tab-link-border-color, $tab-link-border-color);
      border-top: var(--tab-link-active-border-top, $tab-link-active-border-top);
      box-shadow: var(--tab-link-active-shadow, $tab-link-active-shadow);
      padding-bottom: 2px;
      margin-bottom: -2px;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 4px;
        background: var(--tabs-main-bg, $tabs-main-bg);
        position: absolute;
        top: 100%;
        left: 0;
      }

      &.unavailable {
        color: var(--text-shaded);
        border-top-color: var(--link-disable-color);
      }
    }

    &.unavailable {
      pointer-events: none;
    }

    .svg-icon {
      position: relative;
      top: -1px;
    }

    .ca-stages-tabs & {
      border: 0;
      width: 100%;
      font-family: var(--font-main, $font-main);
      font-weight: normal;
      text-transform: none;
      text-align: left;
    }
  }

  .unimportant {
    // .svg-icon {
    //   margin-right: 0 !important;
    // }

    .nav-link {
      @include media-breakpoint-up(md) {
        width: auto;
        min-width: 0;
        max-width: none;
      }
    }
  }
}

.card-header-tab-title {
  padding: 11px 15px 9px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .card-header & {
    padding: 11px 15px 9px;
  }

  .active>& {
    padding-top: 8px;
  }

  &__body {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }

  &__icon {
    flex-shrink: 0;
  }

  &__text {
    flex: 1 0;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icon+&__text {
    margin-left: 7px;
  }

  .badge {
    margin-top: -1px;
    margin-bottom: -1px;
    margin-left: 7px;
    flex-shrink: 0;
    color: var(--badge-color, $badge-color);
    background-color: var(--badge-bg, $badge-bg);
  }
}

.tab-content {
  margin-top: 0;

  .tabs--card>& {
    background-color: var(--tabs-main-bg, $tabs-main-bg);
    border: var(--tabs-main-border, $tabs-main-border);
    box-shadow: var(--tabs-main-shadow, $tabs-main-shadow);
    padding: 15px;
  }

  .tabs--main>& {
    border-top: 1px solid var(--tabs-main-border-color, $tabs-main-border-color);
    padding-top: 16px;
  }

  .tabs--large>& {
    padding: 25px;
  }

  .is-cards-view & {
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }

  .card-body {
    background-color: transparent;
    padding: 15px;

    &.tab-pane {
      background-color: transparent;
      padding: 0;
    }
  }

  .search-panel--tabs & {
    @include media-breakpoint-down(md) {
      background: none;
      box-shadow: none;
      border-width: 1px 0 0;
    }
  }

  .table-responsive {
    margin-bottom: 0;
  }
}

.widget-not-found {
  background-color: var(--tabs-main-bg, $tabs-main-bg);
  border-radius: var(--border-radius, $border-radius);

  .is-cards-view & {
    border: var(--tabs-main-border, $tabs-main-border);
    box-shadow: var(--tabs-main-shadow, $tabs-main-shadow);
  }

  .base-card__body>.entity-list & {
    border: 0;
    box-shadow: none;
  }
}

.tabs--line {
  .nav-tabs {
    margin-bottom: 25px;
    border-bottom: 1px solid var(--tabs-line-main-border-color, $tabs-line-main-border-color);

    .nav-item {
      margin-bottom: -1px;
      min-width: auto;
      margin-right: 40px;
    }

    .nav-link {
      padding: 0;
      outline: none;
      background: transparent;
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 4px solid transparent;
      box-shadow: none;
      min-width: auto;
      font-size: 14px;
      line-height: 22px;
      font-weight: bold;
      font-family: var(--font-heading, $font-heading);
      letter-spacing: 1px;

      &.active {
        border-color: var(--tabs-line-link-border-color);
      }

      &:after {
        display: none;
      }
    }
  }

  .tab-content {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }
}

.c-tabs-select-opt {
  display: flex;
  align-items: center;

  .svg-icon {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    flex-shrink: 0;
  }

  &__text {
    font-family: var(--font-heading, $font-heading);
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-top: 2px;
    flex: 1 0;
  }

  .badge {
    margin-left: 7px;
    flex-shrink: 0;
  }

  &.no-updates .badge {
    display: none;
  }
}

.badge {
  --color: var(--badge-color, $badge-color);
  --bg: var(--primary, $primary);

  min-width: 16px;
  padding: 1px 4px 0;
  font-family: var(--font-heading, $font-heading);
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: normal;
  border-radius: 10px;
  color: var(--color);
  background: var(--bg);
  text-align: center;
  display: inline-block;

  &-primary {
    --bg: var(--primary, $primary);
  }

  &-secondary {
    --bg: var(--secondary, $secondary);
  }

  &-success {
    --bg: var(--success, $success);
  }

  &-info {
    --bg: var(--badge-info-color, $badge-info-color);
  }

  &-warning {
    --bg: var(--warning, $warning);
  }

  &-danger {
    --bg: var(--danger, $danger);
  }

  &-dark {
    --bg: var(--dark, $dark);
  }

  &--exclude {
    --bg: var(--warning, $warning);
  }

  .btn:not([class*="btn-outline"]) & {
    background: var(--main-bg, $main-bg);
    --color: var(--primary, $primary);

    &-primary {
      --color: var(--primary, $primary);
    }

    &-secondary {
      --color: var(--secondary, $secondary);
    }

    &-success {
      --color: var(--success, $success);
    }

    &-info {
      --color: var(--badge-info-color, $badge-info-color);
    }

    &-warning {
      --color: var(--warning, $warning);
    }

    &-danger {
      --color: var(--danger, $danger);
    }

    &-dark {
      --color: var(--dark, $dark);
    }
  }

  .b-dropdown--select & {
    top: 0;
    color: var(--badge-color, $badge-color);
    background: var(--badge-bg, $badge-bg);

    &--exclude {
      --bg: var(--warning, $warning);
    }
  }

  .dropdown-toggle__text+& {
    margin-left: 7px;
  }

  &+& {
    margin-left: 2px;
  }

}

.transition-expand {

  &-enter-active,
  &-leave-active {
    overflow: hidden;
    transition: 0.3s ease-in-out;
    transition-property: max-height, opacity;
  }
}

.tags-appear {

  &-enter,
  &-leave-to {
    opacity: 0;

    &:not(:first-child) {
      max-height: 0;
    }
  }

  &-enter-to,
  &-leave {
    &:not(:first-child) {
      max-height: 40px;
    }
  }

  &-enter-active,
  &-leave-active {
    overflow: hidden;
    white-space: nowrap;
    transition: 0.3s ease-in-out;
    transition-property: opacity, max-height;
    backface-visibility: hidden;
  }
}

.opacity-anim {

  &-enter,
  &-leave-to {
    opacity: 0;
  }

  &-enter-active,
  &-leave-active {
    transition: 0.3s ease-in-out;
    transition-property: opacity;
  }
}

//.is-view-list,
//.is-cards-view,
//.el-activities {
//  .entity-list__header {
//    position: sticky;
//    top: $navbar-height;
//    z-index: 6;
//    background: var(--main-bg, $main-bg);
//  }
//}

.table {
  // position: relative;
  color: var(--table-td-color, $table-td-color);
  border: 0;
  border-collapse: collapse;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  thead {
    th {
      border: 0;
      font-family: var(--font-heading, $font-heading);
      font-weight: bold;
      font-size: 14px;
      line-height: 15px;
      text-transform: uppercase;
      outline: 0;
      vertical-align: bottom;
      color: var(--table-th-color, $table-th-color);
      background: var(--main-bg, $main-bg);
    }
  }

  tbody {
    td {
      border: 0;
      padding: 4px 8px;
      vertical-align: top;
    }
  }

  .column-tags {
    width: 100px;

    .field-project {
      margin-right: -4px;
    }
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--entity-list-table-odd-row-bg, $entity-list-table-odd-row-bg);
}

.card {
  background-color: var(--card-bg, $card-bg);
}

.card-footer {
  background-color: var(--card-item-footer-bg, $card-item-footer-bg);
  border-top-color: var(--card-item-footer-border-color, $card-item-footer-border-color);
}

.v-table {
  &--fw {
    width: 100%;
  }

  .table.b-table>thead>tr>th[aria-sort], .table.b-table>tfoot>tr>th[aria-sort] {
    background: none;

    &::after {
      content: normal;
    }
  }

  &__head-lbl {
    display: flex;
    align-items: flex-start;

    .svg-icon {
      display: inline-block;
      flex-shrink: 0;
      margin-top: -2px;
      margin-right: 5px;
    }

    &__body {
      align-self: center;
    }

    &__text {
      display: inline;
      padding-top: 2px;
    }

    &__sort-icon {
      display: inline;
      white-space: nowrap;
      color: var(--table-sort-icon-color, $table-sort-icon-color);

      .svg-icon {
        display: inline-block;
        margin-top: 0;
        margin-right: 0;
      }
    }

    .svg-icon+&__sort-icon {
      display: block;
      align-self: center;
      margin-left: 0;
    }
  }

  .field-activity-notes {
    &__link {
      display: block;
    }
  }
}


.field-phones {
  .text-phone {
    display: inline-block;
    margin-right: 4px;

    &::after {
      content: ";";
    }

    &:last-child::after {
      content: normal;
    }
  }
}

.fld-contact-list {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    display: inline-block;
    margin-right: 4px;

    &::after {
      content: ";";
    }

    &:last-child::after {
      content: normal;
    }
  }
}


.pagination {
  .page-item {
    margin: 0 1px;

    &.disabled:not([role="separator"]) {
      opacity: 0.5;
      pointer-events: none;
    }

    &[role="separator"] {
      opacity: 0;
      width: 15px;
      display: block !important;
    }
  }

  .page-link {
    min-width: 32px;
    height: 32px;
    padding: 0 4px;
    font-family: var(--font-heading, $font-heading);
    font-weight: bold;
    font-size: 14px;
    line-height: 31px;
    text-align: center;
    color: var(--pagination-color, $pagination-color);
    background: var(--pagination-bg, $pagination-bg);
    border-color: var(--pagination-border-color, $pagination-border-color);
    border-radius: var(--border-radius, $border-radius);
    box-shadow: none;
    transition-duration: 0.2s;
    transition-property: color, background, border-color;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: var(--pagination-hover-color, $pagination-hover-color);
      background: var(--pagination-hover-bg, $pagination-hover-bg);
    }

    &[role="menuitem"] {
      font-size: 22px;
      line-height: 29px;
    }

    .svg-icon {
      display: block;
    }
  }

  .page-item.active {
    .page-link {
      color: var(--pagination-active-color, $pagination-active-color);
      background: var(--pagination-active-bg, $pagination-active-bg);
      border-color: var(--pagination-active-bg, $pagination-active-bg);
    }
  }
}


// Form wizard icon
.wizard-step-icon {
  position: relative;
  width: 20px;
  height: 20px;
  background: var(--wizard-step-icon-background-color, $wizard-step-icon-background-color);
  border: $wizard-step-icon-border-width solid var(--wizard-step-icon-border-color, $wizard-step-icon-border-color);
  border-radius: 50%;
  margin-right: 10px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";
    display: block;
    width: 6px;
    height: 10px;
    border: solid var(--wizard-step-visited-color, $wizard-step-visited-color);
    border-width: 0 2px 2px 0;
    margin-top: -2px;
    transform: rotate(45deg);
  }

  .active & {
    border-color: var(--wizard-step-active-color, $wizard-step-active-color);
    background: var(--wizard-step-icon-background-active-color, $wizard-step-icon-background-active-color);

    &::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background: var(--wizard-step-active-color, $wizard-step-active-color);
      border-radius: 50%;
    }

    &::after {
      display: none;
    }
  }

  .active~* & {
    border-color: var(--wizard-step-icon-border-color, $wizard-step-icon-border-color);

    &::after {
      display: none;
      border-color: var(--wizard-step-icon-border-color, $wizard-step-icon-border-color);
    }
  }

  .active~.visited & {
    &::after {
      display: block;
    }
  }

  .visited & {
    border-color: var(--wizard-step-visited-color, $wizard-step-visited-color);

    &::after {
      border-color: var(--wizard-step-visited-color, $wizard-step-visited-color);
    }
  }

  .visited.active & {
    border-color: var(--wizard-step-active-color, $wizard-step-active-color);
  }
}

.dropdown.b-dropdown .b-dropdown-form {
  padding: 0;
}

.drag-ghost {
  opacity: 0.5;
}

.drag-handle {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.modal-open {
  .cov.cov-position-container {
    z-index: 999;
  }
}

.cov.cov-position-container:not(.foo) {
  z-index: 999;
}

.base-card {
  &__buttons .btn-link {
    line-height: 24px;

    &__icon {
      margin-top: -1px;
    }
  }
}

.info-cols {
  @include media-breakpoint-up(md) {
    //margin: 0 -15px;
    display: flex;
  }

  @include media-breakpoint-up(lg) {
    margin: 0 -10px;
  }

  &__item {
    @include media-breakpoint-up(md) {
      position: relative;
      padding: 0 10px;
      min-width: 0;
      flex: 1 1;

      &:first-child {
        margin-right: 12px;
      }

      .base-card {
        margin-right: -10px;
        margin-left: -10px;
      }
    }

    @include media-breakpoint-up(lg) {
      &:first-child {
        margin-right: 0;
      }

      .base-card {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}

.entity-info {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    //grid-gap: 0px 30px;
    grid-row-gap: 0;
    grid-column-gap: 26px;
  }

  &__item {
    min-width: 0;
  }

  .updates-info {
    &>.card-body, .base-card__body {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .entity-updates {
      flex: 1 1 150px;
      min-height: 0;
      display: flex;

      &__table {
        width: 100%;
      }
    }
  }
}

.error-page-sect {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.c-badge {
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  padding: 1px 2px 0;
  vertical-align: 1px;
  background: #999;
  color: var(--text-inverse-color);

  &--lg {
    padding: 4px 9px;
    font-size: 14px;
    line-height: 16px;
  }

  &--primary {
    background-color: var(--primary);
  }

  &--secondary {
    background-color: var(--secondary);
  }

  &--secondary-light {
    background-color: var(--badge-bg-secondary-light);
  }

  &--warning {
    background-color: var(--warning);
  }
}

.mo-badge {
  padding: 0 4px;
  margin-left: 5px;
  font-size: 12px;
  line-height: 14px;
  height: 14px;
  min-width: 0;
  display: flex;

  &:hover {
    background: var(--danger, $danger);
    color: #fff;
  }

  &__text {
    display: block;
    min-width: 0;
    flex: 1;
  }

  &__clear {
    margin-left: 2px;
    display: flex;
    align-items: center;
    padding: 0 0 0 1px;

    .svg-icon {
      display: block;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.chevron-title {
  --ct-parent-bg: var(--main-bg);
  --ct-bg: var(--stage-chevron-bg);
  --ct-color: var(--stage-chevron-color);
  --ct-icon-color: var(--stage-chevron-icon-color);
  --ct-border-color: var(--stage-chevron-border-color);
  --ct-num-color: var(--stage-chevron-num-color);

  position: relative;
  background-color: var(--ct-bg);
  border: 1px solid var(--ct-border-color);

  &::before,
  &::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    top: -1px;
    border-top: 19px solid transparent;
    border-right: none;
    border-bottom: 19px solid transparent;
    border-left: 6px solid var(--ct-border-color);
    z-index: 0;
  }

  &--lg {

    &::before,
    &::after {
      border-top-width: 21px;
      border-bottom-width: 21px;
    }
  }

  &::before {
    left: 0;

    .nav-item:first-child & {
      content: normal;
    }
  }

  &::after {
    left: calc(100% + 1px);
  }

  &__inner {

    &::before,
    &::after {
      content: '';
      display: block;
      height: 0;
      width: 0;
      position: absolute;
      top: 0;
      border-top: 18px solid transparent;
      border-right: none;
      border-bottom: 18px solid transparent;
      border-left: 6px solid var(--ct-bg);
      z-index: 1;
    }

    .chevron-title--lg & {

      &::before,
      &::after {
        border-top-width: 20px;
        border-bottom-width: 20px;
      }
    }

    &::before {
      left: -1px;
      border-left-color: var(--ct-parent-bg);

      .nav-item:first-child & {
        content: normal;
      }
    }

    &::after {
      left: 100%;
    }
  }

  &__body {
    padding: 7px 3px 7px 11px;
    display: flex;
    align-items: center;
    overflow: hidden;
    color: var(--ct-color);
  }

  &--lg &__body {
    padding: 10px 7px 10px 16px;
  }

  &__icon {
    color: var(--ct-icon-color);
    margin-right: 10px;
    flex-shrink: 0;

    .svg-icon {
      display: block;
      fill: currentColor;
      margin: 0 -3px;
    }
  }

  &__text {
    white-space: nowrap;
    font-size: 14px;
    line-height: 19px;
    min-width: 0;
    flex: 1;
  }

  &__num {
    color: var(--ct-num-color);
  }
}

.ca-stages-tabs .nav-link {
  .chevron-title {
    --ct-bg: var(--ca-tabs-chevron-bg);
    --ct-color: var(--ca-tabs-chevron-color);
    --ct-icon-color: var(--ca-tabs-chevron-icon-color);
    --ct-border-color: var(--ca-tabs-chevron-border-color);
    --ct-num-color: var(--ca-tabs-chevron-num-color);

    &__num {
      margin-left: 8px;
      font-family: var(--font-heading, $font-heading);
      font-weight: bold;
      font-size: 18px;
      line-height: 19px;
    }
  }

  &.active {
    .chevron-title {
      --ct-bg: var(--ca-tabs-chevron-active-bg);
      --ct-color: var(--ca-tabs-chevron-active-color);
      --ct-icon-color: var(--ca-tabs-chevron-active-icon-color);
      --ct-border-color: var(--ca-tabs-chevron-active-border-color);
      --ct-num-color: var(--ca-tabs-chevron-active-num-color);
    }
  }
}

.mini-toastr {
  .mini-toastr-notification {
    &__message {
      display: inline-block;
      max-width: 100%;
      word-break: break-word;
    }
  }
}

.ca-chart-tooltip,
.us-chart-tooltip {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  // width: 320px;
  max-width: 100vw;
  // transform: translate(0, -50%);

  &::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    position: absolute;
    left: -5px;
    top: -7px;
    border: solid var(--card-border-color, $card-border-color);
    border-width: 0 0 1px 1px;
    background: var(--main-bg, $main-bg);
  }

  &.left::after {
    left: auto;
    right: -5px;
    border-width: 1px 1px 0 0;
  }

  &__main {
    position: absolute;
    left: 0;
    min-width: 140px;
    padding: 15px;
    background: var(--main-bg, $main-bg);
    border: $card-border-param var(--card-border-color, $card-border-color);
    border-radius: var(--border-radius, $border-radius);
    box-shadow: $card-shadow-param var(--card-shadow-color, $card-shadow-color);
  }

  &__title {
    font-family: var(--font-heading, $font-heading);
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  &__body {}

  &__note {
    margin-top: 10px;
    font-size: 12px;
    color: var(--secondary, $secondary);
  }
}

.ca-chart-tooltip {
  width: 320px;

  &__main {
    width: 100%;
  }
}

.us-chart-tooltip {
  width: 240px;

  &__main {
    width: 100%;
  }
}

.ca-tooltip-line {
  border-bottom: 1px solid var(--line-color-dark, $line-color-dark);
  padding: 3px 0;
  display: flex;
  align-items: baseline;

  &:last-child {
    border-bottom: 0;
  }

  &__color {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    flex-shrink: 0;
  }

  &__text {
    font-size: 16px;
    line-height: 20px;
    flex: 1;
  }

  &__num {
    display: block;
    font-family: var(--font-heading, $font-heading);
    font-weight: bold;
    font-size: 18px;
  }
}

.us-tooltip-line {
  border-bottom: 1px solid var(--line-color-main, $line-color-main);
  padding: 0 0 8px;
  margin-bottom: 8px;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &__text {
    font-size: 12px;
  }

  &__num {
    font-size: 18px;
  }
}

.card-details {
  &__footer {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid var(--line-color-main, $line-color-main);

    .btn {
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.system-update-btn {
  position: fixed;
  top: 15px;
  right: 15px;
  background-color: var(--system-update-button-bg, $system-update-button-bg);
  color: #fff;
  z-index: 10000;
  padding: 8px 0px;
  border-radius: var(--border-radius, $border-radius);
  font-size: 14px;
  line-height: 16px;
  max-width: calc(100% - 30px);
  display: flex;

  &:hover {
    cursor: pointer;
  }

  &:hover &__append {
    text-decoration: underline;
  }

  &__body, &__append {
    min-height: 36px;
    padding: 0px 24px;
    display: flex;
    align-items: center;
  }

  &__append {
    font-family: var(--font-heading, $font-heading);
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .sub-body {
    border-right: 1px solid var(--system-update-button-border-color, $system-update-button-border-color);

    &__icon {
      margin-right: 12px;
    }
  }
}

html.is-mobile {
  .crop-text__normal {
    display: none;
  }

  .crop-text__mobile {
    display: inline;
  }
}

@mixin windowBorder {

  &::before,
  &::after {
    content: '';
    display: block;
    border: 0 solid var(--logged-as-user-body-border);
    position: fixed;
    z-index: 99999999;
  }
}

html.logged-as-user:not(.printing) {
  --body-bg: var(--logged-as-user-body-bg);
  --header-bg: var(--logged-as-user-body-bg);

  @include windowBorder;

  &::before,
  &::after {
    height: 0;
    width: 100%;
    left: 0;
    border-top-width: 6px;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  body {
    @include windowBorder;

    &::before,
    &::after {
      height: 100%;
      width: 0;
      top: 0;
      border-left-width: 6px;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.tooltip {
  /*--bg-color: var(--tooltip-bg-color, $tooltip-bg-color);
  --text-color: var(--tooltip-text-color, $tooltip-text-color);
  --link-color: var(--tooltip-link-color, $tooltip-link-color);*/

  top: 0;

  &.tooltip2 {
    --tooltip-bg-color: var(--tooltip2-bg-color);
    --tooltip-text-color: var(--tooltip2-text-color);
    --tooltip-link-color: var(--tooltip2-link-color);
    --tooltip-border-color: var(--tooltip2-border-color);
  }

  &.b-tooltip {
    opacity: 1;
  }

  &--entity-status {
    margin-left: -9px;

    &.bs-tooltip-top {
      margin-bottom: 5px;
    }

    &.bs-tooltip-bottom {
      margin-top: 5px;
    }

    .arrow {
      left: 50% !important;
      transform: translateX(-50%);
    }
  }

  .tooltip-inner {
    font-size: 12px;
    line-height: 14px;
    max-width: none;
    padding: 8px;
    text-align: left;
    color: var(--tooltip-text-color, $tooltip-text-color);
    background-color: var(--tooltip-bg-color, $tooltip-bg-color);
    border: 1px solid var(--tooltip-border-color, $tooltip-border-color);
  }

  .tooltip-inner a {
    color: var(--tooltip-link-color, $tooltip-link-color);

    &:hover {
      text-decoration: underline;
    }
  }

  & .arrow {
    overflow: hidden;

    &::before {
      background-color: var(--tooltip-bg-color, $tooltip-bg-color);
      border: 1px solid var(--tooltip-border-color);
      width: $tooltip-arrow-width;
      height: $tooltip-arrow-width;
      transform: rotate(45deg);
    }
  }


  &.bs-tooltip-top {

    .arrow {
      bottom: 1px;
    }

    .arrow::before {
      top: auto;
      bottom: 3px;
    }
  }

  &.bs-tooltip-right {

    .arrow {
      left: 1px;
    }

    .arrow::before {
      right: auto;
      left: 3px;
    }
  }

  &.bs-tooltip-bottom {

    .arrow {
      top: 1px;
    }

    .arrow::before {
      bottom: auto;
      top: 3px;
    }
  }

  &.bs-tooltip-left {

    .arrow {
      right: 1px;
    }

    .arrow::before {
      left: auto;
      right: 3px;
    }
  }

  &.bs-tooltip-auto {

    &[x-placement^="top"] {
      @extend .bs-tooltip-top;
    }

    &[x-placement^="right"] {
      @extend .bs-tooltip-right;
    }

    &[x-placement^="bottom"] {
      @extend .bs-tooltip-bottom;
    }

    &[x-placement^="left"] {
      @extend .bs-tooltip-left;
    }
  }


  //&.bs-tooltip-bottom .arrow:before { border-bottom-color: var(--tooltip-bg-color, $tooltip-bg-color); }
  //&.bs-tooltip-top .arrow:before { border-top-color: var(--tooltip-bg-color, $tooltip-bg-color); }
  //&.bs-tooltip-left .arrow:before { border-left-color: var(--tooltip-bg-color, $tooltip-bg-color); }
  //&.bs-tooltip-right .arrow:before { border-right-color: var(--tooltip-bg-color, $tooltip-bg-color); }
}

.tags-section {
  padding: 8px 0px;
  border-top: 1px solid var(--line-color-main, $line-color-main);
  font-size: 14px;
  line-height: 16px;

  &__title {
    font-weight: 500;
  }

  @include media-breakpoint-up(md) {
    display: flex;

    &__title {
      width: 160px;
      padding-right: 20px;
      flex: none;
    }
  }

  @include media-breakpoint-down(sm) {
    &__title {
      margin-bottom: 15px;
    }
  }

  &__body {
    flex: 1;
  }
}

.tags-subsection {
  padding-top: 8px;
  border-top: 1px solid var(--line-color-main, $line-color-main);

  &:first-child {
    border-top: 0px;
    padding-top: 0px;
  }

  &__title {
    padding-bottom: 8px;
  }

  &__body {
    flex: 1;
  }
}

.tags-group {
  padding: 8px 0px;
  border-bottom: 1px solid var(--line-color-main, $line-color-main);

  @include media-breakpoint-up(md) {
    display: flex;

    &__title {
      width: 160px;
      padding-right: 20px;
      flex: none;
    }
  }

  @include media-breakpoint-down(sm) {
    &__title {
      margin-bottom: 5px;
    }
  }

  &:first-child {
    padding-top: 0px;
  }

  &:last-child {
    border-bottom: 0px;
  }

  &__title {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
  }

  &__body {
    flex: 1;
  }
}

.sm-tags-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;
  margin-bottom: -4px;

  &__item {
    font-size: 12px;
    border: 1px solid var(--line-color-main, $line-color-main);
    padding: 3px;
    margin-right: 4px;
    margin-bottom: 4px;
    color: var(--text-shaded, $text-shaded);
  }
}

.data-table {
  &__header {
    position: sticky;
    top: 55px;
    background: var(--main-bg, $main-bg);
    z-index: 4;
    border-bottom: 1px solid var(--line-color-main, $line-color-main);

    .is-view-list &,
    .is-cards-view &,
    .el-activities & {
      top: 112px;
    }

    &.js-is-scrollable {
      padding-top: 5px;
    }
  }

  &__header-scroll {
    width: 100%;
    overflow-x: auto;
    transform: scaleY(-1);

    .dt-table {
      transform: scaleY(-1);
    }
  }

  &__body {
    width: 100%;
    overflow: auto;
  }
}

.dt-table-wrapper {
  overflow: auto;
}

.dt-table {
  color: var(--table-td-color, $table-td-color);
  border: 0;
  border-collapse: collapse;
  width: 100%;

  &__th {
    font-family: var(--font-heading, $font-heading);
    font-weight: bold;
    text-transform: uppercase;
    outline: 0;
    vertical-align: bottom;
    color: var(--table-th-color, $table-th-color);
    background: var(--main-bg, $main-bg);

    &--sortable {
      cursor: pointer;
    }
  }

  .column-status {
    width: 50px;
  }

  .column-name {
    min-width: 250px;
  }

  .column-entity {
    min-width: 200px;
  }

  .column-id {
    width: 62px;
  }

  .column-icon {
    width: 50px;
    text-align: center;
  }

  .column-min,
  .column-row-number {
    width: 10px;
  }

  .column-checked {
    width: 30px;
  }

  .column-date {
    width: 120px;
  }

  .column-tags {
    width: 100px;
  }

  .column-actions,
  .column-details {
    width: 15px;
    text-align: right;
  }

  .column-status {
    width: 30px;
  }

  .data-table--fixed-header .data-table__body &__th {
    padding-top: 0;
    padding-bottom: 0;
    height: 0;
  }

  @media (min-width: 1366px) {
    .data-table--fixed-required-columns & {
      --row-number-width: 48px;
      --status-width: 70px;
      --notes-width: 40px;
      --tags-width: 110px;
      --name-width: 250px;

      border-collapse: separate;
      border-spacing: 0;

      .required-column {
        position: sticky;
        left: 0;
        z-index: 1;

        &--active:not(.foo) {
          z-index: 3;
        }
      }

      .column-row-number {
        min-width: var(--row-number-width);
        max-width: var(--row-number-width);
        width: var(--row-number-width);
      }

      .column-status {
        left: var(--row-number-width);
        width: var(--status-width);
        min-width: var(--status-width);
        max-width: var(--status-width);
        z-index: 2;
      }

      .column-notes {
        left: calc(var(--row-number-width) + var(--status-width));
        width: var(--notes-width);
        min-width: var(--notes-width);
        max-width: var(--notes-width);
      }

      .column-tags {
        left: calc(var(--row-number-width) + var(--status-width) + var(--notes-width));
        width: var(--tags-width);
        min-width: var(--tags-width);
        max-width: var(--tags-width);
      }

      .column-name {
        left: calc(var(--row-number-width) + var(--status-width) + var(--notes-width));
        min-width: var(--name-width);
      }

      .column-tags+.column-name {
        left: calc(var(--row-number-width) + var(--status-width) + var(--notes-width) + var(--tags-width));
      }

      &__tr:nth-of-type(odd) .required-column {
        background-color: var(--entity-list-table-odd-row-bg);
      }

      &__tr:nth-of-type(even) .required-column {
        background-color: #fff;
      }
    }

    .data-table--fixed-required-columns.data-table--has-scroll & {
      .column-name {
        border-right: 1px solid var(--line-color-main);
      }
    }
  }

  &__th,
  &__td {
    position: relative;
    padding: 10px 12px;

    &.border-left {
      border-left: 1px solid var(--line-color-main);
    }
  }

  &__tr {
    &--details-helper {
      display: none;
    }

    &--details .columns-info {
      max-width: 1300px;
      margin: 0 auto;
      //grid-gap: 6px 32px;
      grid-row-gap: 6px;
      grid-column-gap: 32px;
    }
  }

  &__tr--details &__td {
    position: relative;
    padding-left: 24px;

    &::before {
      content: '';
      display: block;
      height: calc(100% - 20px);
      width: 2px;
      background-color: var(--line-color-main);
      position: absolute;
      top: 10px;
      left: 12px;
    }
  }

  &--striped &__tr {
    --row-bg: #fff;
    --odd-row-bg: var(--entity-list-table-odd-row-bg);

    background-color: var(--row-bg);

    &:nth-of-type(odd) {
      background-color: var(--odd-row-bg);
    }

    &.row-danger {
      --row-bg: var(--table-row-danger-even);
      --odd-row-bg: var(--table-row-danger-odd);
    }

    &.row-success {
      --row-bg: var(--table-row-success-even);
      --odd-row-bg: var(--table-row-success-odd);
    }
  }

  &__td {
    vertical-align: top;

    &--muted {
      color: var(--link-disable-color);
    }
  }

  &--lined &__td {
    border-top: 1px solid var(--line-color-main);
  }

  &--lined &__tr:last-child &__td {
    border-bottom: 1px solid var(--line-color-main);
  }
}

.entity-link {
  font-weight: 500;
  color: var(--table-td-color);

  &:hover {
    color: var(--text-color);
    text-decoration: none;
  }

  .dt-table__td--muted & {
    color: var(--link-disable-color);

    &:hover {
      color: var(--text-muted);
    }
  }
}

.dt-head-label {
  display: flex;
  align-items: flex-start;

  .data-table--fixed-header .data-table__body & {
    height: 0;
    overflow: hidden;
  }

  &__icon {
    flex-shrink: 0;

    .svg-icon {
      display: block;
      width: 18px;
      height: 18px;
    }
  }

  &__body {
    font-size: 0;
    white-space: nowrap;
    align-self: center;
  }

  &__text {
    white-space: normal;
    display: inline;
    font-size: 14px;
    line-height: 15px;

    .dt-table__th--sortable & {
      padding-right: 16px;
    }
  }

  &__sort {
    white-space: nowrap;
    display: inline-block;
    //vertical-align: baseline;
    width: 12px;
    height: 12px;
    font-size: 0;
    color: var(--table-sort-icon-color, $table-sort-icon-color);
    margin-bottom: -2px;

    .svg-icon {
      display: block;
      width: 12px;
      height: 12px;
    }
  }

  &__text+&__sort {
    margin-left: -12px;
  }
}

/* Optimisation */
/* Activity notes */

.an-card {
  --ca-bg: var(--timeline-activity-card-bg);
  --ca-border-color: var(--timeline-activity-card-border-color);
  --ca-title-line: var(--timeline-activity-card-title-line-color);

  width: 100%;
  position: relative;
  background-color: var(--ca-bg);
  border: 1px solid var(--ca-border-color);
  border-radius: var(--border-radius, $border-radius);
  padding: 10px;

  @include media-breakpoint-up(md) {
    padding: 16px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    gap: 0 8px;
    margin-bottom: 8px;
  }

  &__header-icon {
    .svg-icon {
      --size: 14px;

      display: block;
    }
  }

  &__header-body {
    width: 100%;
    flex: 1 0 100%;
    order: 2;
    align-self: center;
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid var(--ca-title-line);

    @include media-breakpoint-up(md) {
      width: auto;
      min-width: 0;
      flex: 1;
      order: initial;
      margin-top: 0;
      padding-top: 0;
      border-top: 0;
    }
  }

  &__header-controls {
    margin-left: auto;
    margin-right: -7px;
  }
}

.ca-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  &__author,
  &__assigned {
    margin-right: 8px;

    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 1px;
      height: 15px;
      border-left: 1px solid currentColor;
      margin-top: -2px;
      margin-left: 8px;
    }
  }
}

.an-controls {
  display: flex;

  &__item {
    --icon-color-1: var(--text-shaded-secondary);
    --icon-color-2: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin: -7px 2px;
    transition: $layout-transition;
    transition-property: --icon-color-1, --icon-color-2;

    &:hover {
      --icon-color-1: transparent;
      --icon-color-2: var(--text-shaded-secondary);
    }

    &--delete {
      --icon-color-1: var(--activity-danger-color);
      --icon-color-2: transparent;

      &:hover {
        --icon-color-1: transparent;
        --icon-color-2: var(--activity-danger-color);
      }
    }

    .svg-icon {
      --size: 16px;

      display: block;
    }
  }
}

.modal-activities,
.modal-notes {
  &.modal .modal-header {
    height: 0;
    margin-bottom: 0;
  }
}

.an-list {
  &__title {
    margin-bottom: 40px;
  }

  &__header {
    margin-bottom: 24px;
  }

  .list-section {
    border-top: 1px solid var(--line-color-main);
    padding: 18px 0px 24px;

    .ls-header {
      margin-bottom: 18px;
      display: flex;
      align-items: center;

      &__title {
        font-size: 22px;
        line-height: 26px;
        font-family: var(--font-heading, $font-heading);
        margin-right: 24px;
      }

      &__total {
        b {
          color: var(--secondary);
        }
      }
    }
  }
}

.status-tag {
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #fff;
  text-transform: uppercase;
  padding: 1px 3px;
  display: inline-block;

  &--disabled {
    background-color: var(--status-tag-disabled-bg);
  }

  &--suspended {
    background-color: var(--status-tag-suspended-bg);
  }

  &--expired {
    background-color: var(--status-tag-expired-bg);
  }

  &--active {
    background-color: var(--status-tag-active-bg);
  }

  &--completed {
    background-color: var(--status-tag-completed-bg);
  }

  &--queue {
    background-color: var(--status-tag-queue-bg);
  }
}

.text-nowrap {
  white-space: nowrap;
}

.big-text {
  font-size: 16px;
  line-height: 19px;
}

.list-info-container {
  border-top: 1px solid var(--line-color-main);
}

.list-info {
  $block: &;

  padding: 0;
  margin: 0;
  list-style: none;

  &__item {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 16px;
    padding: 5px 8px 0;
  }

  &--lined &__item,
  &--striped &__item {
    padding: 7px 8px 2px;
  }

  &--lined &__item {
    border-top: 1px solid var(--line-color-main);
  }

  &--striped &__item:nth-child(2n+1) {
    background: var(--secondary-bg);
  }

  &__label {
    font-weight: bold;
    padding-right: 8px;
    margin-right: auto;
    margin-bottom: 4px;
    color: var(--text-shaded);
    hyphens: auto;

    .svg-icon {
      width: 16px;
      height: 16px;
      margin-top: -1px;
    }
  }

  &--no-justify &__label {
    margin-right: 0;
  }

  &__value {
    max-width: 100%;
    margin-bottom: 4px;
    hyphens: auto;

    &--wide {
      width: 100%;
    }

    .i-block {
      margin-right: 4px;
    }

    .li-icons {
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -10px;
      margin-top: 12px;

      &__item {
        margin-right: 18px;
        margin-bottom: 10px;

        .svg-icon {
          width: 16px;
          height: 16px;
        }
      }
    }

    .li-values {
      padding: 0;
      margin: 0;
      list-style: none;

      &__item {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &:not(.foo) .vt-link__text {
      white-space: unset;
    }
  }

  &--no-justify &__value,
  &--label-column &__value {
    min-width: 0;
    flex: 1;
  }

  &--label-column &__label {
    width: 40%;
    max-width: 150px;
    min-width: 100px;
  }

  &__item--icon &__label {
    width: auto;
    min-width: 0;
  }

  &--table {
    display: table;

    #{$block}__item {
      display: table-row;
    }

    #{$block}__label,
    #{$block}__value {
      display: table-cell;
      padding-top: 5px;
      padding-bottom: 8px;
    }

    #{$block}__label {
      padding-left: 8px;
    }

    #{$block}__value {
      padding-right: 8px;
    }
  }
}

.pcr {
  color: var(--text-secondary-color);
  font-size: 12px;
  line-height: 15px;
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }

  .c-roles-sect & {
    font-size: 14px;
    line-height: 16px;
  }
}

.pcr-date {
  color: var(--text-secondary-color);
  font-size: 12px;
  line-height: 15px;

  .c-roles-sect & {
    font-size: 14px;
    line-height: 16px;
  }
}

.pc-list-roles {
  margin: 0 0 16px;
  padding: 0;
  list-style: none;

  &:last-child {
    margin-bottom: 0;
  }

  &__item {
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      margin-right: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--inline &__item {
    display: inline-block;
    margin-right: 4px;

    &::after {
      content: ';';
    }

    &:last-child::after {
      content: normal;
    }
  }

  &--inline {
    margin-right: -4px;
  }

  .pcr-badge {
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }

  .pcr-dot {
    &::after {
      content: '.';
    }

    &:last-child::after {
      content: '';
    }
  }
}

.d-none {
  display: none;
}

.position-static {
  position: static;
}

.view-loading {
  padding-top: 30px;
  content: '';
  background-color: rgba(255, 255, 255, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;

  @include media-breakpoint-up(lg) {
    width: calc(100% - 200px);
  }

  &--full-page {
    background-color: var(--main-bg, #fff);
    padding: 0;
    min-width: 100%;
    flex: 1 1 100%;
    z-index: $zindex-modal;
  }
}

.preloader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #fff;
  display: flex;
}

.cache-logo {
  position: absolute;
  opacity: 0;
  left: -100vw;
}

.file-icon {
  &--xls {
    color: var(--file-xls-color);
  }

  &--doc {
    color: var(--file-doc-color);
  }

  &--pdf {
    color: var(--file-pdf-color);
  }

  &--eml {
    color: var(--file-eml-color);
  }

  &--ppt {
    color: var(--file-ppt-color);
  }

  &--default {
    color: var(--file-default-color);
  }

  &--error {
    color: var(--file-error-color);
  }

  &--png {
    color: var(--file-png-color);
  }

  &--jpg {
    color: var(--file-jpg-color);
  }
}
