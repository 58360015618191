@import '../variables';

//.field-title {
//  $block: &;
//
//  &--with-icons {
//    display: flex;
//  }
//
//  &--with-icons &__icon {
//    margin-right: 8px;
//  }
//
//  &--inline &__icon {
//    display: inline;
//    margin-right: 8px;
//  }
//
//  .card-item & {
//    #{$block}__link {
//      font-size: 16px;
//      font-weight: bold;
//      line-height: 120%;
//      font-family: var(--font-heading, $font-heading);
//    }
//  }
//}

.field-responsible {
  display: flex;

  &__icon {
    margin-right: 7px;
  }

  .c-avatar {
    --size: 28px;
  }

  &__text {
    align-self: center;
  }
}

//.list-roles {
//  &__contact-role {
//    color: var(--field-roles-color, $field-roles-color);
//    font-weight: bold;
//    font-size: 12px;
//  }
//
//  .role-item {
//    &__name {
//      font-weight: bold;
//    }
//    &__company {
//      font-size: 12px;
//      line-height: 14px;
//      font-weight: bold;
//    }
//    &__name, &__company, &__created {
//      color: var(--field-roles-color, $field-roles-color);
//    }
//  }
//}

ul.field-list {
  & > li {
    line-height: 16px;
    margin-bottom: 7px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.card-item {
  .field {
    //&.is-many {
    //  width: 100%;
    //}

    &__title {
      font-weight: bold;
    }
  }

  .field-roles {
    &.is-many {
      width: 100%;
    }
  }
}

.field-phones,
.field-addresses {
  display: flex;

  &__icon {
    height: 16px;
    display: flex;
    align-items: center;
    margin-right: 7px;
  }

  &__list {
    align-self: center;
    line-height: 16px;
  }
}

.field-media {
  display: flex;

  &__icon {
    margin-right: 8px;

    .svg-icon {
      height: 16px;
      width: 16px;
    }
  }
}
