@import '../variables';

/*
**	UserInit
*/
.user-init {
  .wizard {
    &__steps {
      max-width: 220px;
      align-self: end;
    }
  }
}

/*
**	ProjectsFilter
*/
.trigger-filter-item {
  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.trigger-filter-item {
  &__buttons-row {
    margin: 0 -10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include media-breakpoint-up(md) {
      justify-content: flex-end;
    }

    .btn {
      margin: 0 10px 10px;
    }
  }

  &__total-filter {
    min-height: 380px;
  }
}
