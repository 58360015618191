@import '~@/assets/scss/variables';

.c-date-picker {
  &.vc-container {
    --header-padding: 0 10px;
    --title-padding: 0 8px;
    --arrows-padding: 0 10px 8px;
    --arrow-font-size: 26px;
    --weekday-padding: 5px 0;
    --weeks-padding: 5px 6px 7px 6px;
    --nav-container-width: 170px;
    --day-min-height: 28px;
    --day-content-width: 28px;
    --day-content-height: 28px;
    --day-content-margin: 1.6px auto;
    --day-content-transition-time: 0.13s ease-in;
    --day-content-bg-color-hover: var(--dp-hover-bg, $dp-hover-bg);
    --day-content-bg-color-focus: var(--dp-highlight-bg, $dp-highlight-bg);
    --highlight-height: 28px;
    font-family: var(--font-main, $font-main);
    color: var(--text-shaded, $text-shaded);
  }

  .vc-weeks .vc-grid-cell {
    border-bottom: 1px solid var(--line-color-light, $line-color-light);
  }

  &__header {
    font-family: var(--font-heading, $font-heading);
    font-weight: normal;
  }

  &__title {
    font-family: var(--font-heading, $font-heading);
    font-weight: bold;
    font-size: 18px;
    text-transform: capitalize;
  }

  &__weekdays {
    font-size: 12px;
    line-height: 14px;
    color: var(--text-muted, $text-muted);
  }

  &__popover {
    background-color: var(--main-bg, $main-bg);
    border: 1px solid var(--line-color-main, $line-color-main);
    border-radius: var(--border-radius, $border-radius);
    padding: 8px;
    box-shadow: $card-shadow-param var(--card-shadow-color, $card-shadow-color);
  }

  .is-today .vc-day-content {
    background-color: var(--dp-today-bg, $dp-today-bg);
    color: var(--dp-today-color, $dp-today-color);

    &:hover {
      // background-color: var(--dp-today-hover-bg, $dp-today-hover-bg);
      // color: var(--dp-hover-color, $dp-hover-color);
    }

    &.vc-day-disabled {
      background-color: var(--dp-today-hover-bg, $dp-today-hover-bg);
    }
  }

  // .is-today .vc-highlights + .vc-day-content {
  //   background-color: transparent;

  //   &:focus {
  //     color: var(--dp-hover-color, $dp-hover-color);
  //   }
  // }

  .vc-bg-highlight {
    background-color: var(--dp-highlight-bg, $dp-highlight-bg);
  }

  .vc-bg-range {
    background-color: var(--dp-range-bg, $dp-range-bg);
  }

  .vc-day-content:focus {
    color: var(--dp-highlight-color, $dp-highlight-color);
  }

  .vc-border-brand {
    border-color: var(--dp-highlight-bg, $dp-highlight-bg);
    background: var(--dp-range-bg, $dp-range-bg);
  }

  .vc-day-content.vc-text-brand-900 {
    &:hover {
      background: var(--dp-today-hover-bg, $dp-today-hover-bg);
      // color: var(--dp-hover-color, $dp-hover-color);
    }
  }

  .vc-other-month,
  .vc-day-disabled {
    opacity: 0.5;

    .vc-day-disabled {
      opacity: 1;
    }
  }

  .vc-weeks .vc-grid-cell:nth-child(-n+7) {
    padding-bottom: 2px;
  }

  &.vc-popover-content {
    background-color: var(--main-bg, $main-bg);
    border: 1px solid var(--line-color-main, $line-color-main);
    padding-top: 8px;
    border-radius: var(--border-radius, $border-radius);
    box-shadow: $card-shadow-param var(--card-shadow-color, $card-shadow-color);

    .vc-weeks {
      padding-bottom: 4px;

      .vc-grid-cell:nth-last-child(-n+7) {
        border-bottom: 0;
      }
    }
  }
}

.date-range {
  &__picker {
    margin-bottom: 10px;
  }

  &__result {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
  }
}
