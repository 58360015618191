@import "variables";
// @import "map-marker-colors";

.is-map-view > .card {
  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
  }
}

.base-map {
  --map-legend-width: 260px;

  position: relative;
  height: calc(100vh - 55px);
  container-type: inline-size;

  @include media-breakpoint-up(md) {
    height: 690px;
    max-height: calc(100vh - 95px);
  }

  // &--loading {
  //   pointer-events: none;
  // }

  .vue-map-container {
    height: 100%;
  }

  &__legend {
    position: absolute;
    top: 10px;
    right: 10px;

    &__popup {
      width: 200px;
      max-height: calc(100vh - 85px);
    }
  }

  &__loading {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    z-index: 60;
    width: 40px;
    height: 40px;
    border-radius: var(--border-radius, $border-radius);
    background: var(--modal-backdrop-color, $modal-backdrop-color);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-inverse-color, $text-inverse-color);
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in-out;
    transition-property: visibility, opacity;
    pointer-events: none;
  }

  &--loading &__loading {
    visibility: visible;
    opacity: 1;
  }
}

.base-map-place-items {
  position: absolute;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  bottom: 0;
  left: 0;
  z-index: 9;
  display: flex;

  @include media-breakpoint-up(sm) {
    width: 320px;
  }

  @include media-breakpoint-up(md) {
    top: 0;
    bottom: auto;
    left: 0;
    padding-right: 5px;
    max-height: 100%;
  }

  &__body {
    padding: 10px;
    overflow: auto;
    flex: 1;
  }

  //.expandable {
  //  .list-unstyled,
  //  .card-footer {
  //    display: none;
  //
  //    @include media-breakpoint-up(md) {
  //      display: block;
  //    }
  //  }
  //
  //  .list-unstyled.row {
  //    display: none;
  //
  //    @include media-breakpoint-up(md) {
  //      display: flex;
  //    }
  //  }
  //
  //  &.is-shown {
  //    .list-unstyled,
  //    .card-footer {
  //      display: block;
  //    }
  //
  //    .list-unstyled.row {
  //      display: flex;
  //    }
  //  }
  //}
}

.btn-legend {
  width: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--btn-color, $btn-color);
  background: var(--primary, $primary);
  padding: 0;
  @include button-active-variant(var(--btn-color, $btn-color), var(--btn-primary-active-bg, $btn-primary-active-bg));
  @include button-shadow-variant(var(--focus-shadow-color, $focus-shadow-color), var(--active-shadow-color, $active-shadow-color));

  @container (max-width: 700px) {
    &.btn-primary {
      --height: 42px;
    }
  }

  @container (min-width: 700px) {
    width: auto;
    height: auto;
  }

  &__icon {
    .svg-icon {
      --size: 18px;

      display: block;
    }

    @container (min-width: 700px) {
      margin-right: 10px;
    }
  }

  &__text {
    display: none;
    flex: 1 1;

    @container (min-width: 700px) {
      display: block;
      font-weight: bold;
      font-size: 12px;
    }
  }

  // @include media-breakpoint-up(md) {
  //   width: auto;
  //   height: auto;
  //
  //   &__icon {
  //     margin-right: 10px;
  //   }
  //
  //   &__text {
  //     display: block;
  //     font-weight: bold;
  //     font-size: 12px;
  //   }
  // }
}

.po-map-legend {
  z-index: 9;

  &.popover.b-popover {
    display: flex;
    font-size: 16px;
    line-height: 20px;

    @include media-breakpoint-up(md) {
      font-size: 14px;
      // margin-left: var(--map-legend-offset);
    }
  }

  @include media-breakpoint-down(sm) {
    position: fixed !important;
    //top: calc(#{$navbar-height} + 10px) !important;
    top: 10px !important;
    right: 10px;
    bottom: 10px;
    left: 10px !important;
    transform: none !important;
    max-width: none;
    margin-right: 0;
    z-index: 33333333;

    .arrow {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    width: var(--map-legend-width);
    min-height: 120px;
    max-height: calc(100% - 50px - 130px);
  }

  .popover-body {
    width: 100%;
    max-height: 100%;
    color: var(--text-color, $text-color);
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
  }

  &__header {
    padding: 0 10px;
    margin-bottom: 12px;
    display: flex;
    align-items: flex-start;
  }

  &__title {
    min-width: 0;
    flex: 1 0;
    align-self: center;
    font-family: var(--font-heading, $font-heading);
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;

    @include media-breakpoint-up(md) {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__close {
    width: 32px;
    height: 32px;
    padding: 4px;
    color: var(--secondary, $secondary);

    &:hover {
      color: var(--primary, $primary);
    }

    @include media-breakpoint-up(md) {
      width: 24px;
      height: 24px;
    }

    .svg-icon {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__body {
    flex: 1 1;
    height: 100%;
    overflow: auto;
    padding: 0 10px;
  }

  .custom-switch {
    padding: 0;

    .custom-control-label {
      padding: 5px 43px 5px 8px;

      &::before {
        top: 10px;
        right: 8px;
      }

      &::after {
        top: 9px;
        right: 8px;
      }

      @include media-breakpoint-down(sm) {
        padding: 10px 55px 10px 10px;

        &::before {
          top: 14px;
          right: 8px;
          width: 36px;
          height: 14px;
        }

        &::after {
          top: 11px;
          right: 8px;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          transform: translateX(-16px);
        }
      }
    }

    &--label-highlite .custom-control-input:checked ~ .custom-control-label {
      color: var(--text-color, $text-color);
      background: var(--secondary-bg, $secondary-bg);
    }
  }
}

.legend-list-wrap {
  // max-height: calc(100vh - 85px - 16px);
  overflow: auto;

  @include media-breakpoint-up(md) {
    max-height: 480px;
  }
}

.legend-list {
  margin: 5px 0 0;
  padding: 0;
  list-style: none;

  & + & {
    margin-top: 30px;
  }

  &__item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 7px;
    }
  }
}

.cat-lbl {
  display: flex;
  align-items: center;

  &__icon {
    margin-right: 10px;
    color: var(--map-main-color, $map-main-color);
    flex-shrink: 0;
    align-self: flex-start;

    .svg-icon {
      display: block;

      &.green {
        fill: var(--map-updated-color, $map-updated-color);
      }
    }
  }

  &__text {
    min-width: 0;
    flex: 1 1;
  }
}

.m-cluster {
  position: relative;
  width: 48px;
  height: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img {
      width: 100%;
    }
  }

  &__num {
    position: relative;
    width: 100%;
    font-weight: bold;
    font-size: 12px;
    color: #fff;
    text-align: center;
  }
}

.m-group {
  position: relative;
  width: 48px;
  height: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transform-origin: 50%;
  transition: transform 0.3s;
  // transition: 0.3s;
  // transition-property: width, height, margin;

  &--active {
    transform: scale(1.3);
    // width: 64px;
    // height: 64px;
    // margin: -8px 0 0 -8px;
  }

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__icon {
    width: 100%;
    height: 100%;
    color: var(--text-inverse-color, $text-inverse-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__num {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 20px;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    background-color: var(--secondary, $secondary);
    color: #fff;
    border-radius: 20px;
    transition: 0.3s;
    transition-property: transform;
  }

  &--active &__num {
    transform: translate(-3px, 3px) scale(0.77);
  }
}

.m-marker {
  position: relative;
  width: 24px;
  height: 34px;
  cursor: pointer;
  transform-origin: 50% 30px;
  transition: transform 0.3s;

  &--active {
    transform: scale(1.7);
  }

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__icon {
    position: relative;
    width: 100%;
    height: 22px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;

    .svg-icon {
      display: block;
    }

    // .svg-icon--p-samlingslokaler {
    //   margin-bottom: -3px;
    // }
  }
}

.map-marker {
  --border-color: var(--map-marker-border);
  --fill-color: var(--map-marker-fill);

  position: relative;
  width: 24px;
  height: 34px;

  &__border {
    color: var(--border-color);
  }
  &__fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--fill-color);
  }
}

.current-location-btn {
  position: absolute;
  right: 7px;
  bottom: 85px;
  width: 30px;
  height: 30px;
  padding: 0;
  background: #fff;
  outline: none !important;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(#000, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;

  &:hover {
    color: #000;
  }

  .svg-icon {
    display: block;
  }
}

.current-location-marker {
  width: 8px;
  height: 8px;
  background: var(--map-current-color, $map-current-color);
  border-radius: 50%;
}

.legend-updated-only {
  margin-bottom: 16px;

  @include media-breakpoint-up(md) {
    margin-bottom: 10px;
  }
}
