html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: var(--font-main, $font-main);
  background: var(--body-bg);
  color: var(--text-color);
}

body {
  min-height: 100vh;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  &:has(.dashboard-container):not(.modal-open) {
    overflow-y: scroll;
  }
}

.app {
  position: relative;
  display: flex;
  min-height: 100vh;
}

%layout-transition {
  transition: $layout-transition-speed ease-in-out;
  -webkit-transition-property: margin-left, width, -webkit-box-flex;
  transition-property: margin-left, width, flex, -webkit-box-flex, -ms-flex;
}

.app-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;

  @extend %layout-transition;
}

.app-content {
  //margin-top: $navbar-height;
  flex: 1 1;
}


.main {
  position: relative;
  flex: 1;
  min-width: 0;
  padding-top: 20px;

  .container-full-height & {
    height: 100%;
  }

  .dashboard-container & > .view-loading {
    background: var(--body-bg);
    margin-top: -85px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 24px;
  }

  &::before {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-sticky;
    height: 100%;
    background: var(--modal-backdrop-color);
    opacity: 0;
    visibility: hidden;
    transition: $layout-transition-speed ease-in-out;
    transition-property: opacity, visibility;

    .sidebar-show & {
      visibility: visible;
      opacity: 1;
    }
  }
}

.page-content {
  margin-bottom: 24px;

  .container-full-height & {
    height: 100%;
  }
}
