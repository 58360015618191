.dashboard-widget {
  margin-bottom: 0;

  &__title {
    margin-bottom: 24px;
    text-transform: uppercase;
  }

  &__entity-title .field-title {
    &__title {
      &:where(:not(:last-child)) {
        margin-bottom: 4px;
      }
    }

    &__text {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;
    }

    &__subtitle {
      font-weight: normal;
      font-size: 14px;
      line-height: 1.2;
    }
  }
}

