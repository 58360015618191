.search-panel {
  $panel-height: calc(100% - #{$navbar-height});

  background-color: var(--tabs-main-bg, $tabs-main-bg);
  border: var(--tabs-main-border, $tabs-main-border);
  box-shadow: var(--tabs-main-shadow, $tabs-main-shadow);
  opacity: 0;
  position: fixed;
  width: 100%;
  height:  $panel-height;
  max-height: $panel-height;
  margin-bottom: 0;
  bottom: $panel-height;
  left: 0;
  z-index: 1000;
  transition: 0.3s;
  transition-property: transform, opacity;

  @include media-breakpoint-up(sm) {
    height: auto;
  }

  @include media-breakpoint-up(lg) {
    position: relative;
    opacity: 1;
    bottom: 0;
    z-index: 20;
    transition: none;
    margin-bottom: 16px;

    &.card {
      border-radius: var(--border-radius, $border-radius);
    }
  }

  .show-mob-search & {
    opacity: 1;
    transform: translateY(100%);

    @include media-breakpoint-up(lg) {
      transform: translateY(0);
    }
  }

  &--tabs {
    padding: 10px 15px 15px;

    @include media-breakpoint-up(md) {
      padding: 15px 30px 25px;
    }

    @include media-breakpoint-up(lg) {
      background: none;
      box-shadow: none;
      border: 0;
      padding: 0;
    }

    .tab-content .card-body {
      @include media-breakpoint-down(md) {
        padding: 15px 0 0;
      }
    }
  }

  &--has-save-searches &__sec-buttons {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
    }
  }

  &--has-save-searches &__sec {
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  &--disabled &__body,
  &--disabled &__sec {
    --input-color: var(--input-color-disabled);
    --input-bg: var(--input-bg-disabled);
    --badge-bg: var(--link-disable-color);
    color: var(--text-muted) !important;
    pointer-events: none;

    a, button, button .btn__text {
      color: var(--link-disable-color) !important;
      border-color: var(--link-disable-color) !important;
    }
  }

  &.card {
    border-radius: 0;
  }

  .card-body {
    @include media-breakpoint-down(xs) {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &__mob-title {
    margin-bottom: 10px;
    font-family: var(--font-heading, $font-heading);
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: 1px;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .card-body {
    padding: 10px 15px 15px;

    @include media-breakpoint-up(md) {
      padding: 15px 30px 25px;
    }

    @include media-breakpoint-up(lg) {
      padding: 16px;
    }
  }

  &__body {

  }

  &__main {
    @include media-breakpoint-up(sm) {
      margin: 0 -7px;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__sec {
    @include media-breakpoint-up(sm) {
      margin: 0 -7px 0;
      display: flex;
      flex-wrap: wrap;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: -8px;
    }
  }

  // &__body {
  //   display: grid;

  //   @include media-breakpoint-up(md) {
  //     grid-template-columns: 1fr 1fr 1fr 130px 140px;
  //     grid-template-areas:
  //     "f-main-col   f-main-col   f-main-col   f-main-col  main-btn"
  //     "f-sec-col    f-sec-col    f-sec-col    f-sec-col   main-btn"
  //     "sec-flt-col  sec-flt-col  sec-flt-col  btn-sec     btn-sec";
  //     column-gap: 16px;
  //   }

  //   @include media-breakpoint-up(xl) {
  //     grid-template-columns: 1fr 1fr 130px 140px;
  //     grid-template-areas:
  //     "f-main-col   f-sec-col    f-sec-col   main-btn"
  //     "sec-flt-col  sec-flt-col  btn-sec     btn-sec";
  //     column-gap: 16px;
  //   }
  // }

  &__field-main {
    min-width: 0;
    margin-bottom: 16px;

    @include media-breakpoint-up(md) {
      grid-area: f-main-col;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 10px;
    }
  }

  &__fields-other {
    min-width: 0;
    margin-bottom: 16px;

    @include media-breakpoint-up(md) {
      grid-area: f-sec-col;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 10px;
    }
  }

  // &__sec-filters {
  //   min-width: 0;
  //   margin-bottom: 10px;

  //   @include media-breakpoint-up(md) {
  //     margin-bottom: 0;
  //     grid-area: sec-flt-col;
  //   }
  // }

  &__sec-filters {
    padding: 0 7px;
    margin-bottom: 8px;
    width: 100%;
    flex: 1 0 100%;

    @include media-breakpoint-up(md) {
      //margin-bottom: 0;
      //width: 60%;
      flex: 1;
    }
  }

  &__btn-main {
    min-width: 0;
    margin-bottom: 32px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 24px;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      grid-area: main-btn;
    }
  }

  &__btn-sec {
    min-width: 0;

    @include media-breakpoint-up(md) {
      grid-area: btn-sec;
      justify-content: flex-end;
    }
  }

  &__main {
    min-width: 0;
    // margin-bottom: 16px;

    // @include media-breakpoint-up(md) {
    //   grid-area: 1/1/2/5;
    // }

    @include media-breakpoint-up(xl) {
      // grid-area: 1/1/2/4;
      margin-bottom: -3px;
    }
  }


  &__kwd-body {
    min-width: 0;

    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: center;
    }
  }

  &__kwd-field {
    margin-bottom: 16px;

    @include media-breakpoint-up(sm) {
      margin-right: 16px;
      margin-bottom: 0;
      flex: 1;
    }
  }

  &__kwd-btn {
    @include media-breakpoint-up(sm) {
      width: 140px;
      flex-shrink: 0;
    }
  }

  .b-dropdown--select {
    width: 100%;
  }


  .dropdown-popup {
    //min-width: 100%;
    max-width: calc(100vw - 60px);
  }

  .dropdown-form {
    width: 100%;

    &:focus {
      outline: none;
    }
  }


  &__top {
    .dropdown {
      width: 100%;
    }
  }

  &__bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    &__left {
      display: flex;
      flex-wrap: wrap;
    }
    &__right {
      display: flex;
      justify-content: flex-end;
    }

    .search-item {
      margin-right: 10px;
    }
  }

  @include media-breakpoint-down(sm) {
    &__bottom {
      &__left, &__right {
        width: 100%;
      }

      &__left {
        margin-bottom: 1rem;
      }
    }
  }

  .base-switcher {
    padding: 6px 8px;

    //&.indeterminate,
    //&.checked,
    //&.checked-2 {
    //  background-color: var(--more-options-check-active-bg, $more-options-check-active-bg);
    //}
  }

  .dashboard-container & .checkboxes-related.is-mobile {
    .checkboxes-related-column:not(:first-child) {
      top: 0;
      height: calc(100% - 15px);
    }
  }
}

.search-dropdown {
  .dropdown-form {
    padding: 0 8px 8px;

    &__header {
      font-size: 16px;
      line-height: 19px;
      padding: 16px 8px;
      border-bottom: 1px solid var(--line-color-main);
    }
  }

  .date-offset-picker {
    padding: 0 8px;
  }
}

.modal-search {
  /*.modal-content {
    height: 100vh;
  }

  .modal-content {
    height: 100vh;
  }*/

  .modal-body {
    @include media-breakpoint-down(xs) {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}

.search-form,
.selector-form {
  &__body {
    padding-top: 12px;
    padding-bottom: 12px;
    min-height: 120px;

    .search-more-options & {
      padding: 0;
    }
  }

  &__scroll {
    max-height: 280px;
    overflow-y: auto;
    padding-right: 4px;
  }

  @include media-breakpoint-down(xs) {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;
    flex: 1 1;

    &__body {
      padding: 0;
      min-height: 0;
      flex: 1 1;
      display: flex;
      flex-direction: column;

      .checkboxes-related {
        margin-bottom: 0;
        min-height: 0;
        flex: 1 1;
        display: flex;
      }

      .checkboxes-related__lists {
        min-height: 0;
      }

      .checkboxes-related-column {
        min-height: 0;
        max-height: 100%;
      }

      .checkboxes-related-column__list {
        min-height: 0;
        max-height: 100%;
        flex: 1 1;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    border-top: 1px solid;
    border-color: var(--search-form-sep-border-color, $search-form-sep-border-color);
    padding: 15px 8px 8px;
    // margin-top: 15px;
    justify-content: space-between;

    .apply-btn {
      min-width: 160px;
    }
  }

  &__actions {
    font-family: var(--font-heading, $font-heading);
    text-transform: uppercase;
    font-weight: 700;
    line-height: 100%;
    color: var(--search-form-action-link-color, $search-form-action-link-color);
  }

  &__action--link {
    display: inline-block;
    padding: 10px 0;
    color: currentColor;
    letter-spacing: 1px;
    max-width: 150px;

    &:hover {
      text-decoration: underline;
      color: var(--search-form-action-link-hover-color, $search-form-action-link-hover-color);
    }
  }
}

.selector-form {
  &--height-limited &__body-inner {
    max-height: 280px;
    overflow-y: auto;

    .modal-search & {
      max-height: 100%;
    }
  }
}

.sp-search-fields {
  @include media-breakpoint-up(sm) {
    margin: 0 -8px;
    display: flex;
  }

  &__item {
    width: 100%;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 5px;
    }

    @include media-breakpoint-up(sm) {
      padding: 0 8px;
      margin-bottom: 0;
      max-width: 33.33%;
      flex: 1 0 33.33%;
    }
  }
}

.sp-sec-filters {
  padding-top: 10px;

  @include media-breakpoint-up(sm) {
    margin: 0 -20px 0 0;
    display: flex;
    flex-wrap: wrap;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: -15px;
    padding-top: 5px;
  }

  &__item {
    margin-bottom: 15px;

    @include media-breakpoint-up(sm) {
      margin-right: 40px;
    }

    //& > .custom-control .custom-control-label::before, .custom-control-label::after {
    //    top: 0;
    //}
  }
}

.sp-fields {
  @include media-breakpoint-up(sm) {
    margin: 0 -8px;
    display: flex;
  }

  &__item {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 5px;
    }

    @include media-breakpoint-up(sm) {
      padding: 0 8px;
      margin-bottom: 0;
      min-width: 0;
      flex: 1 1 1px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .dropdown-toggle {
      min-width: 0;
      max-width: 100%;
    }
  }
}

.flt-media {
  &__header {
    display: flex;
  }

  &__icon {
    margin-right: 7px;
  }

  &__title {
    display: block;
    font-family: var(--font-heading, $font-heading);
    font-size: 18px;
    line-height: 18px;
    font-weight: var(--search-dd-btn-text-font-weight, $search-dd-btn-text-font-weight);
    margin-bottom: 1px;

    @include media-breakpoint-up(md) {
      font-size: 14px;
      text-transform: var(--search-dd-btn-text-transform, $search-dd-btn-text-transform);
      letter-spacing: var(--search-dd-btn-text-letter-spacing, $search-dd-btn-text-letter-spacing);
    }
  }

  &__body {
    display: flex;

    @include media-breakpoint-up(sm) {
      padding-left: 24px;
    }
  }

  & > .d-flex.mr-3 {
    margin-right: 7px !important;
  }

  .svg-icon--accounting {
    margin-top: 2px;
  }

  .custom-link {
    .btn-link {
      font-size: 14px;
      line-height: 17px;

      @include media-breakpoint-up(md) {
        font-size: 12px;
        line-height: 15px;
      }

      .btn__text {
        color: var(--text-shaded, $text-shaded);
      }
    }

    &.text-highlight {
      .btn-link {
        color: var(--secondary, $secondary);

        .btn__text {
          color: currentColor;
        }
      }
    }
  }
}

.link-f-sel {
  display: inline-block;
  min-width: 160px;
  // padding: 0 0 10px;

  &::after {
    content: "";
    display: inline-block;
    vertical-align: 0.255em;
    position: relative;
    width: 7px;
    height: 7px;
    border: solid currentColor;
    border-width: 0 1px 1px 0;
    opacity: 0.7;
    margin-left: 5px;
    top: -1px;
    transform: rotate(45deg);
  }
}

.sp-fields-group {
  flex-wrap: wrap;

  @include media-breakpoint-up(sm) {
    display: flex;
    flex: 1 0 100%;
  }

  @include media-breakpoint-up(xl) {
    flex: 1 0 60%;
    flex-wrap: nowrap;
  }
}

.sp-field--wide2 + .sp-fields-group {
  @include media-breakpoint-up(xl) {
    flex: 1 0 50%;
  }
}

.sp-field--wide3 + .sp-fields-group {
  @include media-breakpoint-up(xl) {
    flex: 1 0 50%;
  }

  @media (min-width: 1800px) {
    flex: 1 0 420px;
  }
}

.sp-field {
  margin-bottom: 15px;
  min-width: 0;

  @include media-breakpoint-up(sm) {
    padding: 0 7px;
    flex: 1 0 20%;

    &--wide {
      width: 100%;
      flex: 1 0 100%;
    }

    &--wide2 {
      width: 50%;
      flex: 1 0 50%;
    }

    &--wide3 {
      width: auto;
      flex: 1 0 100%;
    }
  }

  @include media-breakpoint-up(md) {
    &--wide3 {
      width: auto;
      flex: 1 0 50%;
    }
  }

  @include media-breakpoint-between(md,lg) {
    &--wide3:first-child {
      flex: 1 0 100%;
    }
  }

  @include media-breakpoint-up(xl) {
    &--wide {
      width: auto;
      flex: 1 0 40%;
    }

    &--wide2 {
      width: auto;
      flex: 1 0 24%;
    }

    &--wide3 {
      width: auto;
      flex: 1 0 50%;
    }
  }

  @media (min-width: 1800px) {
    &--wide3 {
      width: auto;
      flex: 1 0 22.22%;
    }
  }
}

.apply-btn {
  &__total {
    margin-left: 7px;
    color: var(--search-form-apply-button-total-color);
  }
}

.selector-form,
.search-form,
.search-panel,
.checkboxes-related,
.checkboxes-tree,
.search-more-options {
  .base-switcher {
    padding: 6px 8px;
  }
}

.sf-content {
  padding-left: 8px;
  padding-right: 8px;
}

@include media-breakpoint-up(sm) {
  .search-field {
    .dropdown-popup {
      //min-width: 100%;
      width: 370px;
      max-width: calc(100vw - 20px);
    }

    &--more-options .dropdown-popup {
      width: 540px;
    }

    &--date-range .dropdown-popup {
      width: 540px;
    }

    &--checkboxes-related .dropdown-popup {
      width: 470px;
    }

    &--overflow-scroll .selector-form__body-inner {
      max-height: 250px;
      overflow-y: auto;
    }
  }
}

.search-filter {
  &--coworker-statuses .switcher-label__icon {
    color: var(--primary);
    --ico-color-2: var(--secondary);
  }
}
