@import "functions";
@import "../themes/theme-main-colors";

$zindex-dropdown:       1000;
$zindex-sticky:         1020;
$zindex-fixed:          1030;
$zindex-modal-backdrop: 1040;
$zindex-modal:          1050;
$zindex-popover:        1060;
$zindex-tooltip:        1070;

$font-main: "Roboto", Helvetica, Arial, sans-serif;
$font-heading: "Roboto Condensed", Helvetica, Arial, sans-serif;
$font-size-base: 14;
$font-family-sans-serif: var(--font-main, $font-main);

$font-light: 300;

$main-gap-x: 30px;
$secondary-gap-x: 20px;

$navbar-height: 55px;

$layout-transition-speed: .25s;
$layout-transition: $layout-transition-speed ease-in-out;

// Sidebar
$sidebar-width: 210px;
$sidebar-width-mobile: 280px;
$sidebar-minimized-width: 48px;
$sidebar-header-padding-y: 22px;
$sidebar-header-padding-x: 22px;

$sidebar-nav-link-padding-y: 8px;
$sidebar-nav-link-padding-x: 22px;

$border-radius: 4px;
$border-radius-lg: 4px;
$border-radius-sm: 3px;

// Forms
$focus-shadow-options: 0 0 0 3px;

// Buttons
$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 1rem;
$input-btn-font-family: var(--font-heading, $font-heading);
$input-btn-font-size: 13px;
$input-btn-line-height: 1.539;

// Inputs
$input-padding-y: 0.5625rem;
$input-padding-x: 1rem;
$input-font-family: var(--font-main, $font-main);
$input-font-size: 13px;
$input-line-height: 1.539;
$input-padding-y-lg: $input-padding-y;
$input-padding-x-lg: $input-padding-x;
$input-font-size-lg: $input-font-size;
$input-line-height-lg: $input-line-height;

// Selects
$select-arrow-width: 35px;
$select-arrow-bg-size: 10px 6px;
$select-arrow-bg-position: right 15px center;

// Card
$card-shadow-param: 0 2px 4px;
$card-border-param: 1px solid;

// Form wizard
$wizard-step-icon-border-width: 2px;
$wizard-buttons-sect-border-width: 1px;

// Modal
$modal-padding-x: 32px;
$modal-padding-y: 25px;

// Tooltip
$tooltip-arrow-width: 12px;
$tooltip-arrow-height: 7px;
