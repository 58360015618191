.b-dropdown {}

.dropdown-menu {
  padding: 0;
}

.dropdown-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: var(--dropdown-select-item-color);

  &:hover {
    color: var(--dropdown-select-item-hover-color);
    text-decoration: none;
    background-color: var(--dropdown-select-item-hover-bg);

    & > .svg-icon {
      color: var(--dropdown-select-icon-hover-color);
    }
  }

  &.active,
  &:active {
    color: var(--dropdown-select-item-active-color);
    text-decoration: none;
    background-color: var(--dropdown-select-item-active-bg);

    & > .svg-icon {
      color: currentColor;
    }
  }

  .svg-icon {
    transition: $layout-transition-speed;
    transition-property: color;
  }

  & > .svg-icon {
    display: block;

    &:first-child {
      margin-left: -4px;
    }
  }

  &__text {
    flex: 1;
    min-width: 0;
  }

  .svg-icon + &__text {
    margin-left: 8px;
  }
}

.dropdown-form {
  .dropdown-popup & {
    width: 100%;
  }
}
