.trigger-filter-item {
  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 17px;
  }

  &__tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    margin-right: 15px;
  }

  &__tag {
    padding: 0px 7px;
    border-left: 1px solid #000;
    margin: 2px 0px;

    &:first-child {
      padding-left: 0px;
      border-left: none;
    }

    &:last-child {
      padding-right: 0px;
    }
  }

  .wizard {
    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 225px;
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 245px;
    }

    &__steps {
      margin-left: 17px;
    }

    .active .wizard-step__title {
      @include media-breakpoint-up(md) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    &__body {
    }

    &__content,
    &__buttons {
      @include media-breakpoint-up(md) {
        max-width: 745px;
      }
    }

    .wtp {
      margin-bottom: 7px;

      @include media-breakpoint-up(md) {
        margin-bottom: 40px;
      }
    }
  }

  .wizard-step {
    &__header {
      // margin-bottom: 18px;
    }
  }
}

.filter-section {
  &__header {
    border-bottom: 1px solid var(--wizard-step-line-color-lg, $wizard-step-line-color-lg);
    margin-bottom: 6px;

    @include media-breakpoint-up(md) {
      margin-bottom: 12px;
    }

    p {
      max-width: 525px;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 15px;
      }

      &:last-of-type {
        // margin-bottom: 27px;
      }
    }
  }
}

.mob-search-location {
  .wtp__info,
  .filter-geography-text,
  .field-geography__view-switcher,
  .collapse-filter__item:not(.collapse-filter__item--geography),
  .wizard .collapse-filter__badge,
  .wizard .collapse-filter__title {
    display: none;
  }

  .trigger-filter-item .wizard .wtp {
    margin-bottom: 0;
  }

  .wtp__header:not(foo) {
    border-bottom-width: 4px;
    padding-bottom: 3;
  }

  .wtp__img {
    .svg-icon {
      width: 24px !important;
      height: 24px !important;
    }
  }

  .wtp__num:not(foo) {
    font-size: 24px;
    line-height: 26px;
  }

  .filter-tags__list {
    margin-top: 10px;
  }

  .collapse-filter__item:not(foo) {
    padding-left: 0;
  }
}

.ff-header {
  margin-bottom: 3px;
  display: flex;
  align-items: baseline;

  @include media-breakpoint-up(md) {
    margin-bottom: 15px;
  }

  &__title {
    font-family: var(--font-heading, $font-heading);
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    flex: 1 1;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__actions {
    // min-width: 5px;
    // min-height: 34px;
    text-align: right;
    color: var(--triggers-filter-action-link-color, $triggers-filter-action-link-color);
    font-family: var(--font-heading, $font-heading);
    text-transform: uppercase;
    font-weight: bold;
    line-height: 100%;

    a {
      display: inline-block;
      padding: 10px 0;
      color: currentColor;
      letter-spacing: 1px;

      &:hover {
        text-decoration: underline;
        color: var(--triggers-filter-action-link-hover-color, $triggers-filter-action-link-hover-color);
      }
    }
  }
}

.filter-tags {
  @include media-breakpoint-down(sm) {
    display: none;

    .filter-section--show-tags & {
      display: block;
    }
  }

  &__list {
    margin-top: 25px;

    .collapse-filter__collapsed & {
      margin-top: 0;
    }
  }

  &__list-anim {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

/* .filter-total {
  padding-left: 30px;

  @include media-breakpoint-up(md) {
    background: var(--filter-total-bg, $filter-total-bg);
    border: 1px solid var(--filter-total-border-color, $filter-total-border-color);
    border-radius: var(--border-radius, $border-radius);
    padding: 12px 1rem 14px;
  }

  &__header {
    font-family: var(--font-heading, $font-heading);
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--filter-total-header-color, $filter-total-header-color);
    padding-bottom: 5px;
    border-bottom: 8px solid currentColor;
    margin-bottom: 9px;
  }

  &__title {
    display: flex;
    align-items: flex-start;
  }

  &__img {
    flex-shrink: 0;
    margin-right: 15px;

    @include media-breakpoint-up(md) {
      margin-top: 5px;
    }

    .svg-icon {
      display: block;
    }
  }

  &__num {
    flex: 1 1;
    font-size: 36px;
    line-height: 36px;

    @include media-breakpoint-up(md) {
      font-size: 43px;
      line-height: 42px;
      margin-bottom: -1px;
    }
  }

  &__note {
    color: var(--filter-total-note-color, $filter-total-note-color);
    font-size: 12px;
    line-height: 15px;
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }

    .svg-icon {
      vertical-align: middle;
      margin-top: -2px;
      margin-right: 3px;
    }
  }
} */

.projects-filter {
  &__items {
    margin: 0;
  }
}
