.sidebar {
  @extend %layout-transition;

  display: flex;
  flex-direction: column;
  margin-left: - $sidebar-width-mobile;
  width: $sidebar-width-mobile;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-sticky + 1;
  color: var(--sidebar-color);
  background: var(--sidebar-bg);
  box-shadow: 2px 0 4px var(--sidebar-shadow);

  .sidebar-show & {
    margin-left: 0;
  }

  @include media-breakpoint-up(lg) {
    order: -1;
    margin-left: 0;
    width: var(--sidebar-width);
  }

  &__footer {
    position: relative;
    padding: 12px 0;
    flex-direction: column;
    background: var(--sidebar-bg);
    z-index: 1;
    overflow: hidden;

    &--has-chat {
      padding-bottom: 106px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 16px;
      right: 10px;
      border-top: 1px solid var(--sidebar-line-color);

      @include media-breakpoint-up(lg) {
        right: 16px;
      }
    }

    .sidebar-minimized & {
      display: flex;
      justify-content: center;
      width: var(--sidebar-with-minimized);
      overflow: visible;

      &:before {
        left: 8px;
        right: 8px;
      }
    }
  }

  &__close {
    display: block;
    outline: none;
    padding: 10px;
    margin-top: -5px;
    margin-right: 15px;
    margin-left: -15px;
    flex: 0;
    color: var(--sidebar-color, $sidebar-color);
    background-color: transparent;
    opacity: .8;

    &:hover {
      opacity: 1;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }

    .svg-icon {
      display: block;
    }
  }

  &__brand {
    display: block;
    max-width: 159px;
    padding: 0;
    margin-right: auto;
    flex: 1 1;
    transition-duration: 0.3s;
    transition-property: opacity, visibility, width;

    @include media-breakpoint-up(lg) {
      .sidebar-minimized &,
      .sidebar-animate & {
        position: absolute;
        width: 24px;
        visibility: hidden;
        opacity: 0;
        transition-duration: 0.3s;
        transition-property: visibility, width;
      }
    }

    img {
      max-width: 100%;
    }
  }

  &__minimizer {
    display: none;
    width: 24px;
    height: 24px;
    border: 0;
    outline: none;
    padding: 0;
    background: none;
    position: static;
    margin-bottom: 28px;
    flex: none;
    color: var(--sidebar-minimizer-color, $sidebar-minimizer-color);
    cursor: pointer;
    transition-duration: 0.3s;
    transition-property: color;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    &:active,
    &:focus {
      outline: none;
    }

    &:active,
    &:hover {
      background: none;
      color: var(--sidebar-minimizer-hover-color, $sidebar-minimizer-hover-color);
    }

    .sidebar-minimized & {
      width: 24px;
      height: 24px;
      position: static;
      background: none;
    }

    &::before {
      content: normal;
    }
  }

  &__nav {
    position: relative;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;

    .sidebar-minimized & {
      @include media-breakpoint-up(lg) {
        overflow: visible;
      }
    }
  }
}

.sidebar-minimized .sidebar section :not(.nav-dropdown-items) > .nav-item:last-child::after {
  content: normal;
}

.sidebar-setup-assistant {
  .sidebar-minimized &,
  .sidebar-animate & {
    display: none;
  }
}

.main-menu {
  margin: 0;
  padding: 0;
  list-style: none;

  @include media-breakpoint-up(lg) {
    width: var(--sidebar-width);
  }

  &__item {
    position: relative;
    padding-bottom: 10px;
    transition: $layout-transition-speed ease-in-out;
    transition-property: background-color, color;
    z-index: 1;

    &::after {
      content: "";
      bottom: 0;
    }

    &:first-child::before {
      content: "";
      top: 0;
    }

    &::before,
    &::after {
      display: block;
      position: absolute;
      right: 10px;
      left: 16px;
      border-top: 1px solid var(--sidebar-line-color);

      @include media-breakpoint-up(lg) {
        right: 16px;

        .sidebar-minimized & {
          content: normal;
        }
      }
    }

    &:last-child::after {
      display: none;
    }

    &.mm-dropdown {
      pointer-events: none;
    }
  }
}

.mm-link {
  $mm-link: &;

  position: relative;
  display: flex;
  align-items: center;
  color: var(--sidebar-nav-link-color);
  font-size: 14px;
  line-height: 17px;
  min-height: 40px;
  padding: 10px;
  text-decoration: none;
  transition: $layout-transition-speed ease-in-out;
  transition-property: color, background-color, opacity;
  cursor: pointer;

  @include media-breakpoint-up(lg) {
    line-height: 16px;
    min-height: 32px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &:hover {
    text-decoration: none;
    color: var(--sidebar-nav-link-hover-color);
    background: var(--sidebar-nav-link-hover-bg);
  }

  &.router-link-exact-active {
    color: var(--sidebar-nav-submenu-link-active-color);
    background: var(--sidebar-nav-link-active-bg);
  }

  .main-menu__item > & {
    font-size: 20px;
    line-height: 24px;
    padding: 15px 40px 5px 20px;
    color: var(--sidebar-nav-menu-title-color);
    pointer-events: all;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
      line-height: 19px;
    }

    &.active {
      color: var(--sidebar-nav-menu-title-active-color);
      background: transparent;
    }

    &:is(:hover) {
      body:not(.sidebar-minimized) & {
        color: var(--sidebar-nav-link-active-color);
        background: none;
      }

      .sidebar-minimized & {
        color: var(--sidebar-nav-submenu-link-active-color);
        background: var(--sidebar-nav-link-hover-bg);
      }
    }

    .sidebar-minimized & {
      @include media-breakpoint-up(lg) {
        justify-content: center;
        padding: 10px;
      }
    }

    .sidebar-minimized .mm-dropdown:hover & {
      @include media-breakpoint-up(lg) {
        background: var(--sidebar-nav-link-hover-bg);
      }
    }
  }

  .main-menu__item.active > & {
    color: var(--sidebar-nav-menu-title-active-color);
    background: transparent;
  }

  &--d-toggle {
    &::after {
      position: absolute;
      top: calc(50% + 4px);
      right: 22px;
      width: 7px;
      height: 7px;
      border: solid #fff;
      border-width: 0 1px 1px 0;
      opacity: 0.7;
      padding: 0;
      margin-top: -4px;
      content: "";
      transform: rotate(45deg);
      transition: $layout-transition-speed ease-in-out;
      transition-property: transform;

      .open & {
        top: calc(50% + 8px);
        transform: rotate(-135deg);
      }

      //.open & {
      //  transform: rotate(90deg);
      //}
    }

    .sidebar-minimized & {
      @include media-breakpoint-up(lg) {
        &::after {
          content: normal;
        }
      }
    }
  }

  &--main {
    font-size: 16px;
    line-height: 20px;
    padding: 14px 10px 14px 40px;

    @include media-breakpoint-up(lg) {
      font-size: 14px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;

      .sidebar-minimized & {
        padding-left: 22px;

        &.has-links {
          padding-right: 35px;

          &::after {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border: solid var(--sidebar-nav-dropdown-indicator-color);
            border-width: 0 1px 1px 0;
            position: absolute;
            top: calc(50% - 6px);
            right: 15px;
            transform: rotate(45deg);
          }

          &.active::after {
            border-color: var(--sidebar-nav-link-active-color);
          }
        }
      }
    }

    &.active {
      font-weight: 500;
      color: var(--sidebar-nav-link-active-color);
    }
  }

  &--internal {
    padding-left: 56px;

    @include media-breakpoint-up(lg) {
      font-size: 12px;
      line-height: 16px;

      .sidebar-minimized & {
        padding-left: 28px;
      }
    }

    &::before {
      content: "";
      display: block;
      width: 2px;
      position: absolute;
      top: -6px;
      bottom: -6px;
      left: 41px;
      background: var(--sidebar-nav-submenu-line-color);
      z-index: 10;

      .sidebar-minimized & {
        @include media-breakpoint-up(lg) {
          content: normal;
        }
      }
    }

    .nav-internal-links__item:first-child &::before {
      top: 8px;
    }

    .nav-internal-links__item:last-child &::before {
      bottom: 8px;
    }

    &.router-link-exact-active::before,
    .nav-internal-links__item:first-child &.router-link-exact-active::before,
    .nav-internal-links__item:last-child &.router-link-exact-active::before {
      top: 6px;
      bottom: 6px;
      background: var(--sidebar-nav-submenu-line-active-color);
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .main-menu__item > #{$mm-link} & {
      display: none;

      .sidebar-minimized & {
        @include media-breakpoint-up(lg) {
          display: flex;
          margin-right: 0;
        }
      }
    }

    #{$mm-link}--internal & {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      color: var(--sidebar-nav-submenu-icon-color);
    }

    #{$mm-link}.router-link-exact-active & {
      color: var(--sidebar-nav-submenu-active-icon-color);
    }
  }

  &__text {
    min-width: 0;
    flex: 1;

    .main-menu__item > #{$mm-link} & {
      .sidebar-minimized & {
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }
  }

  &__badge {
    &:is(.badge) {
      --color: var(--sidebar-nav-badge-color);
      --bg: var(--sidebar-nav-badge-bg);
    }

    .sidebar-minimized & {
      @include media-breakpoint-up(lg) {
        margin-top: -1px;
        margin-bottom: -1px;
      }
    }
  }
}

.mm-dropdown {
  &__toggle {}
}

.nav-dropdown-items {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 0;
  overflow-y: hidden;
  transition: max-height $layout-transition-speed ease-in-out;
  pointer-events: all;

  .open & {
    max-height: 1500px;
  }

  &__title {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    .sidebar-minimized & {
      display: none;
    }

    .sidebar-minimized .mm-dropdown:hover & {
      display: block;
    }
  }
}

.nav-internal-links {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;

  .active + & {
    display: block;
  }
}

.submenu-items {
  @include media-breakpoint-up(lg) {
    .sidebar-minimized & {
      top: 0;
      left: 100%;
      box-shadow: var(--sidebar-nav-dropdown-shadow);
      position: absolute;
      width: 210px;
      max-height: 1500px;
      background: var(--sidebar-bg);
      padding: 0 0 8px 0;

      &__title {
        display: block;
        padding: 10px 22px;
        font-size: 16px;
        line-height: 24px;
        color: var(--sidebar-nav-dropdown-title-color);
      }
    }
  }
}
