/**
  Fade
 */

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.fade-in-enter-active {
  transition: opacity .3s;
}

.fade-in-enter /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.fade-slide-down-enter-active,
.fade-slide-down-leave-active {
  transition: 0.3s ease-in-out;
  transition-property: opacity, transform;
}

.fade-slide-down-enter,
.fade-slide-down-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

/**
  Fade out
 */

.fade-out-leave-active {
  transition: opacity .3s;
}

.fade-out-leave-to {
  opacity: 0;
}

/**
  List
 */

//.list-leave-active {
//  transition: all .5s;
//}
//.list-leave-to {
//  opacity: 0;
//  //transform: translateX(30px);
//}
