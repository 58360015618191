%body-1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

%body-2 {
  font-size: 14px;
  font-style: normal;
  line-height: 1.4;
}

%body-caption {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
