@import "../variables";


.notif-content {
  @include media-breakpoint-up(md) {
    padding: 30px;
    background: #fff;
  }
}

.notif-filter-btn {
  .svg-icon {
    margin: -2px 0;
  }
}

.notification-admin {
  .form-group {
    max-width: 680px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.notif-filter {
  &__label {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin-top: -5px;
    margin-bottom: 5px;
  }

  label {
    font-weight: normal;
    margin-bottom: 4px;
  }
}

.notif-table {
  .table:last-child {
    margin-bottom: 0;
  }
}

.table-notif-id {
  width: 60px;
}

.table-notif-actions {
  width: 80px;
  white-space: nowrap;

  @include media-breakpoint-up(md) {
    width: 120px;
  }
}

.notif-act-btn {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  min-width: 35px;
  height: 30px;
  padding: 4px 5px;
  justify-content: center;

  @include media-breakpoint-up(md) {
    min-width: 90px;
    padding: 2px 12px;
  }

  &:last-child {
    margin-right: 0;
  }

  .svg-icon {
    margin: -2px 0;

    @include media-breakpoint-up(md) {
      margin-right: 7px;
    }

    &--outline-edit {
      margin-left: -5px;
      margin-right: -5px;

      @include media-breakpoint-up(md) {
        margin-right: 4px;
      }
    }
  }

  &__text {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}

.notif-translations-sect {
  margin-top: 40px;

  &__title {
    margin-bottom: 20px;
  }

  .nt-has-subj {
    margin-bottom: 30px;
  }
}

.notif-mode-sect {
  max-width: 680px;
  margin-bottom: 1rem;

  .col {
    min-width: 100%;

    @include media-breakpoint-up(sm) {
      min-width: 0;
    }
  }
}

.notif-buttons {
  .btn {
    min-width: 120px;
  }
}

.n-filter-search {
  .svg-icon {
    margin: -6px 0 -3px ;
  }

  &__clear {
    padding-right: 7px;
    padding-left: 7px;
    background: $gray-100;
  }
}

.n-tag-name {
  display: inline-block;
}

.c-tags {
  &__list {
    margin: -7px -7px 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
}

.c-tag {
  background: $gray-100;
  margin-top: 7px;
  margin-right: 7px;
  display: inline-flex;

  .table-striped tbody tr:nth-of-type(odd) & {
    background: $gray-200;
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    padding: 5px 15px;

    .b-table & {
      padding: 5px 8px;
    }
  }

  &--permanent &__title {
    padding-right: 15px;
  }

  &__del {
    width: 30px;
    margin-left: -10px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: var(--danger, $danger);
    }
  }

  &--permanent &__del {
    display: none;
  }

  &__del-icon {
    display: block;
    width: 13px;
    height: 13px;
    position: relative;
    transform: rotate(45deg);

    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: currentColor;
      position: absolute;
      top: 6px;
      left: 0;
    }

    &::after {
      transform: rotate(90deg);
    }
  }
}
