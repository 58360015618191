// Here you can add other styles

/*
**	General
*/

.drag-handle {
  cursor: move;
}

.text-clip {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@include media-breakpoint-down(sm) {
  .main .container-fluid {
    padding: 0 15px;
  }
}

/*
**	Forms
*/

.custom-v-select {
  &.is-invalid {
    & ~ .invalid-feedback {
      display: block;
    }

    & .dropdown-toggle {
      border-color: #f86c6b !important;
    }
  }
}

.tabs {
  .tab-pane {
    outline: none;
  }
}

.b-dropdown {
  &.custom-link {
    .btn-link {
      text-align: left;
      padding: 0px;
      color: var(--link-color, $link-color);

      &:hover {
        color: var(--link-hover-color, $link-hover-color);
      }
    }
  }

  &.dropdown-options .dropdown-toggle {
    height: 20px;
    display: flex;
    align-items: center;
  }

  .dropdown-toggle-no-caret {
    padding: 0;
    border: 0;
    line-height: 1;
  }
}

.dropdown-menu {
  box-shadow: 0 2px 4px var(--shadow-main);
}

/*
**	Cards
*/

.base-card-title {
  font-family: var(--font-heading, $font-heading);
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--card-title-color, #735977);
  margin-bottom: 10px;
}

.list-unstyled,
.c-list-unstyled {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0px;
}
