.modal-backdrop {
  background-color: var(--modal-backdrop-color, $modal-backdrop-color);
  opacity: 1;
}

.modal {
  @include media-breakpoint-down(sm) {
    padding: 0 !important;
  }

  .modal-dialog {
    padding: 0;
    margin: 0;

    @include media-breakpoint-down(sm) {
      min-height: 100%;

      &.modal-dialog-scrollable,
      &.modal-dialog-scrollable .modal-content {
        max-height: 100%;
      }
    }

    @include media-breakpoint-down(md) {
      max-width: initial;
    }

    @include media-breakpoint-up(md) {
      padding: 0 5px;
      margin: 20px auto;

      &.modal-dialog-scrollable,
      &.modal-dialog-scrollable .modal-content {
        max-height: calc(100% - 40px);
      }
    }

    @include media-breakpoint-up(lg) {
      padding: 0 15px;
      margin: 30px auto;

      &.modal-dialog-scrollable,
      &.modal-dialog-scrollable .modal-content {
        max-height: calc(100% - 60px);
      }
    }

    &.modal-xl {
      max-width: 1200px;
    }
  }

  .modal-content {
    position: relative;
    padding: $modal-padding-y/2 $modal-padding-x/2;

    @include media-breakpoint-down(sm) {
      border-radius: 0;
      border: 0;
      min-height: 100vh;
    }

    @include media-breakpoint-up(lg) {
      padding: $modal-padding-y $modal-padding-x;
    }
  }

  .modal-header {
    padding: 0;
    border: 0;
    margin-bottom: 32px;
    //margin-bottom: 16px;
    //
    //@include media-breakpoint-up(lg) {
    //  margin-bottom: 32px;
    //}

    .close {
      display: block;
      width: 32px;
      height: 32px;
      padding: 8px;
      margin: 0;
      outline: 0;
      color: var(--modal-header-close-color, $modal-header-close-color);
      float: none;
      overflow: hidden;
      opacity: 1;
      position: absolute;
      top: 10px;
      right: 10px;
      transition: color 0.2s;
      z-index: 1;

      &:hover {
        color: var(--modal-header-close-hover-color, $modal-header-close-hover-color);
      }

      .svg-icon {
        display: block;
      }
    }
  }

  //.modal-title {
  //  @extend .h1;
  //  font-family: var(--font-heading, $font-heading);
  //  line-height: 34px;
  //  margin-bottom: 0;
  //}

  .modal-title {
    line-height: 100%;
    padding-right: 30px;
  }

  .modal-body {
    padding: 0;
  }

  .modal-dialog-scrollable .modal-body {
    padding-right: 12px;
  }

  .modal-footer {
    padding: 25px 0 0;
    border-color: var(--line-color-main, $line-color-main);
    border-width: 1px 0 0;
    margin-top: 32px;

    .btn {
      min-width: 160px;
      font-family: var(--font-heading, $font-heading);
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;

      &.btn-link {
        min-width: 100px;
      }
    }
  }

  .modal-footer-body {
    width: 100%;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    gap: 20px 16px;

    &__content {
      margin-right: auto;
      padding-right: 16px;
    }

    &__buttons {
      display: flex;
      gap: 8px;
    }
  }
}

.modal-activities {
  .modal-dialog {
    max-width: 1200px;
  }

  &--export .modal-dialog {
    max-width: 500px;
  }
}

.modal-field-details {
  .CodeMirror {
    height: auto;
  }
}

.modal-releases {
  .modal-dialog {
    max-width: 968px;
  }

  .modal-header {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--line-color-main);
  }
}

.modal--form {
  .modal-dialog {
    max-width: 620px;

    .modal-body {
      max-width: 430px;
      width: 100%;
      margin: 0 auto;
    }
  }
}
