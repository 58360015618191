.base-card {
  $block: &;

  background: var(--main-bg, $main-bg);
  border: $card-border-param var(--card-border-color, $card-border-color);
  box-shadow: $card-shadow-param var(--card-shadow-color, $card-shadow-color);
  margin: 0 -15px 10px;
  border-radius: 0;

  @include media-breakpoint-up(md) {
    margin-bottom: 16px;
    margin-right: 0;
    margin-left: 0;
    border-radius: var(--border-radius, $border-radius);
  }

  @include media-breakpoint-up(lg) {
    margin: 0 0 16px;
  }

  & > .card-body,
  &:not(:has(.card-body)) {
    padding: 16px;
  }

  & > .card-body {
    flex: none;
  }

  &__header {
    display: flex;

    &-inner {
      flex: 1;
    }
  }

  &__collapse-wrap {
    padding: 0;

    @include media-breakpoint-up(md) {
      padding: 0 35px;
    }
  }

  &--no-extra-paddings &__collapse-wrap {
    padding: 0;
  }

  &__link-collapse {
    display: block;
    margin-right: 10px;
    padding: 5px;

    .svg-icon {
      display: block;
      color: var(--card-collapse-icon-color);
      transform: rotate(-90deg);
    }
  }

  &.visible &__link-collapse {
    .svg-icon {
      transform: rotate(0deg);
    }
  }

  &__title {
    font-family: var(--font-heading, $font-heading);
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-transform: uppercase;
    color: var(--card-title-color);
    padding-top: 1px;
    letter-spacing: .5px;

    &__link {
      color: var(--card-title-color);

      &:hover {
        text-decoration: none;
      }
    }

    &__brackets-text {
      display: inline-block;
      color: var(--card-title-brackets-text-color);
    }
  }

  &__body {
    margin-top: 10px;
  }

  .collapse,
  .collapsing {
    .entity-list {
      margin-top: -10px;
    }
  }

  &--admin-section {
    & > .card-body {
      @include media-breakpoint-up(lg) {
        padding: 24px;
      }
    }

    .base-card__body {
      padding-top: 16px;
      margin-top: 8px;
      border-top: 1px solid var(--line-color-main);
    }
  }

  &__header-inner {
    display: flex;
  }

  &__title {
    margin-right: auto;
  }

  .tab-content & {
    &__header {
      background: var(--secondary-bg);
      padding: 16px;
    }

    &__body {
      padding: 0 16px 16px 16px;
    }

    & > .card-body {
      padding: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
