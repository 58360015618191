@media print {
  @page {
    size: auto;
    margin: 1.5cm 1cm 1.5cm;

    :left {
      @bottom-left {
        content: "Page " counter(page) " of " counter(pages);
      }
    }

    :right {
      @bottom-left {
        content: "Page " counter(page) " of " counter(pages);
      }
    }
  }

  html {
    font-size: 10pt;
  }

  body {
    width: auto;
    height: auto;
    background: white;
    padding: 0;

    * {
      float: none !important;
    }
  }

  .cov,
  .sidebar,
  .app-header,
  .no-print,
  .popup-accept-policy,
  .mini-toastr {
    display: none !important;
  }

  .app,
  .app-body,
  .app-content {
    display: block;
  }

  .app-content,
  main {
    display: block;
    margin: 0 !important;
    padding: 0 !important;
  }

  .main .container-fluid {
    padding: 0;
    margin: 0;
  }
}

.print-area-wrapper,
.print-controls__body {
  @media screen {
    max-width: 1150px;
  }
}

.print-loading {
  position: fixed;
  top: 55px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  background: var(--loading-overlay-bg-sec);
  transition: left var(--animation-duration);

  @include media-breakpoint-up(lg) {
    left: var(--sidebar-width);
  }
}

.print-area {
  --logo-width: 11.9rem;
  --logo-height: calc(var(--logo-width));
  --border-color: #d1d3d4;
  --col-gap: 2rem;
  --info-item-padding-y: .3rem;
  --color-text-main: #000;
  --color-text-grey: #808285;

  @media screen {
    display: none;
    //position: fixed;
    //width: 100%;
    //height: 100%;
    //top: 0;
    //left: 0;
    //z-index: 1000000;
    //background: var(--secondary-bg, $secondary-bg);
    //padding: 20px;
    //overflow: auto;

    .print-area-wrapper & {
      display: block;
    }
  }

  &__table {
    width: 100%;
    table-layout: fixed;
  }

  &__header {
    padding: 1.6rem 0 2.5rem;

    //@media screen {
    //  position: sticky;
    //  z-index: 10;
    //  top: 55px;
    //  background: var(--secondary-bg, $secondary-bg);
    //}
  }

  &__footer {
    //@media screen {
    //  position: sticky;
    //  bottom: 0;
    //  background: var(--secondary-bg, $secondary-bg);
    //}
  }

  &__content {
    color: var(--color-text-main);
  }
}

.print-header {
  display: flex;
  align-items: center;

  //@media print {
  //  position: fixed;
  //  top: 0;
  //}

  @media screen {
    position: sticky;
    z-index: 10;
    top: 55px;
    //background: var(--secondary-bg, $secondary-bg);
  }

  &--helper {
    visibility: hidden;
    position: static;

    @media screen {
      display: none;
    }
  }

  &__logo {
    width: 11.9rem;
  }

  &__logo-img {
    width: 100%;
  }

  &__text {
    flex: 1;
    text-align: right;
    font-size: .8rem;
  }
}

.print-footer-helper,
.print-footer {
  //display: flex;
  //align-items: flex-start;
  width: 100%;
  padding: 32px 0 16px;
  //background: #fff;

  &__table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  &__body {
    display: flex;
  }

  &__company {
    vertical-align: top;
    padding-right: 24px;
  }

  &__addr {
    vertical-align: top;
    padding-right: 16px;
  }

  &__contacts {
    vertical-align: top;
  }
}

.print-footer-helper {
  visibility: hidden;
}

.print-footer {
  //position: fixed;
  //bottom: 0;
}

.print-list {
  &--contact {
    columns: 2 350px;
    column-gap: 3.2rem;
  }
}

.print-entity {
  margin-bottom: 2.6rem;
  break-inside: avoid;

  &__title {
    font-family: var(--font-main, $font-main);
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin: 0 0 1rem;

    &--unknown {
      font-style: italic;
    }
  }

  &__s-info {
    margin-bottom: .8rem;

    .print-list--tender & {
      margin-bottom: 1rem;
    }
  }

  &__body {
    border-top: 1px solid var(--border-color);

    .print-list--tender & {
      border-top: 0;
    }
  }

  &__cols {
    margin-top: -1px;
    columns: 2 350px;
    column-gap: var(--col-gap);

    .print-list--contact & {
      columns: auto;
    }
  }

  &__sections {
    margin-top: 20px;
  }
}

.info-section {
  margin-bottom: 1.6rem;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  &__header {
    font-size: 1rem;
    line-height: 1.2;
    margin-bottom: .8rem;
    display: flex;
    align-items: flex-start;
  }

  &__icon {
    margin-right: .6rem;
    flex-shrink: 0;
  }

  &__title {
    margin-top: .2rem;
    text-transform: uppercase;
    align-self: center;
  }

}

.print-info {
  overflow: hidden;
  //border-top: 1px solid var(--border-color);

  &__list {
    margin: -1px 0 0;
    padding: 0;
    list-style: none;
    columns: 2 350px;
    column-gap: var(--col-gap);
  }
}

.info-item {
  border-top: 1px solid var(--border-color);
  display: flex;
  font-size: .9rem;
  line-height: 1.2;
  padding: var(--info-item-padding-y) 0;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  &__label {
    width: 10rem;
    margin-right: 1rem;
    font-weight: bold;
    color: var(--color-text-grey);
    align-self: flex-start;

    .print-list--contact & {
      width: 5rem;
    }

    .print-project-info & {
      width: 8rem;
    }
  }

  &__value {
    word-break: break-word;
    min-width: 0;
    flex: 1;
    align-self: flex-end;

    a {
      text-decoration: none;
      color: var(--color-text-main);
    }

    .svg-icon {
      margin-right: 4px;
    }

    .pc-list-roles__item {
      display: inline;

      &::after {
        content: ";";
        margin-right: 5px;
      }

      &:last-child::after {
        content: normal;
      }
    }

    .entity-subtitle {
      font-size: .7rem;
      line-height: .9rem;
      margin-top: .2rem;
    }
  }
}

.print-details {
  &__title {
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-bottom: 1.6rem;

    &.field-title .entity-status-badge {
      font-size: .7rem;
      padding: .2rem .4rem;
      line-height: .8rem;
      vertical-align: 4px;
    }
  }
}

.pd-subtitle {
  margin-top: -1rem;
  margin-bottom: 1.5rem;
}

.pd-subtitle-sect {
  display: flex;
  flex-wrap: wrap;

  &__label {
    margin-right: .5rem;
  }
}

.pd-sect,
.pd-sub-sect {
  &__header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 1rem;
    height: 1rem;
    margin-right: .5rem;

    .svg-icon {
      display: block;
      width: 100%;
      height: 100%;
      margin-top: -1px;
    }
  }

  &__title {
    font-size: 1rem;
    line-height: 1.2rem;
    text-transform: uppercase;
  }
}

.pd-sect {
  margin-bottom: 3rem;

  &--tender-info {
    display: flex;
  }

  //&--info &__header {
  //  margin-bottom: .5rem;
  //}

  &--description &__body .project-description {
    &__sect {
      margin-bottom: 1rem;
    }

    h3 {
      font-family: var(--font-main, $font-main);
      font-size: .8rem;
      line-height: 1rem;
      text-transform: uppercase;
      margin-bottom: .2rem;
    }

    p {
      font-size: .9rem;
      line-height: 1.1rem;
      margin-bottom: 1rem;
    }
  }
}

.pd-sub-sect {
  .pd-sect--tender-info & {
    flex: 1;

    &:first-child {
      padding-right: 1rem;
    }

    &:last-child {
      padding-left: 1rem;
    }
  }
}

.pds-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.print-item-description {

}

.print-update-info {
  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__list {
    .updates-info__table {
      padding: 0;

      tr {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
      }
    }

    .column-date {
      text-align: right;
      padding-right: 32px;
      width: auto;
      white-space: nowrap;

      .text-muted {
        color: var(--color-text-grey) !important;
      }
    }

    .column-text {
      width: 100%;
    }

    .upd-line {
      display: inline;
      margin-right: 7px;
      color: var(--color-text-main);

      &::after {
        content: ".";
      }
    }
  }
}

.pu-item {
  font-size: .9rem;
  line-height: 1.2;
  display: flex;
  align-items: center;
  padding-right: 10px;

  &--editor {
    margin-right: auto;
  }

  &__label {
    font-weight: bold;
    color: var(--color-text-grey);
    margin-right: 7px;
  }
}

.print-materials {
  columns: 2 350px;
  column-gap: var(--col-gap);
}

.print-materials-sect {
  margin-bottom: 2rem;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  &__title {
    font-weight: bold;
    font-size: .8rem;
    line-height: .9rem;
    text-transform: uppercase;
    margin-bottom: .5rem;
  }

  [role="cell"] {
    padding: .3em 0;
    border-top: 1px solid var(--border-color);
  }

  [role="row"]:last-child [role="cell"] {
    border-bottom: 1px solid var(--border-color);
  }
}

.print-table {
  --c-border-th: #979899;
  --c-border-td: #d1d3d4;
  --padding-x: .6rem;
  --padding-y: .4rem;

  width: 100%;

  &__th {
    vertical-align: bottom;
    border-top: 1px solid var(--c-border-th);
    border-bottom: 1px solid var(--c-border-th);
    padding: var(--padding-y) var(--padding-x);

    &:first-child {
      padding-left: 0;
    }

    &.column-date,
    &.column-icon {
      width: auto;
      text-align: left;
    }

    &__label {
      margin-right: 4px;
    }
  }

  &__td {
    color: var(--color-text-main);
    border-top: 1px solid var(--c-border-td);
    padding: var(--padding-y) var(--padding-x);
    vertical-align: top;
    font-size: .9rem;
    line-height: 1.1rem;

    &:first-child {
      padding-left: 0;
    }

    .tr-contact & {
      border-top: 0;

      &:first-child {
        padding-left: 1rem;
      }
    }

    .tr-contact + .tr-contact & {
      padding-top: .2rem;
    }

    .field-title--list-with-contacts.field-company .field-title__link {
      font-family: var(--font-main, $font-main);
      font-weight: bold;
      font-size: .9rem;
      line-height: 1.1rem;
    }

    /*.list-roles {
      &__item {
        margin-bottom: var(--padding-y);
        line-height: 1.1rem;
      }
      .role-item__name {
        color: var(--color-text-main);
        font-weight: normal;
      }

      .role-item__created,
      .role-item__company {
        color: var(--color-text-main);
        font-weight: normal;
        font-style: italic;
        font-size: .9rem;
        line-height: 1.1rem;
      }
    }*/

    .pc-list-roles {
      &__item {
        margin-bottom: var(--padding-y);
        line-height: 1.1rem;
      }

      .pcr {
        color: var(--color-text-main);
        font-weight: normal;
      }

      .pcr-date {
        color: var(--color-text-main);
        font-weight: normal;
        font-style: italic;
        font-size: .9rem;
        line-height: 1.1rem;
      }
    }

    .field-phones__icon {
      display: none !important;
    }

    .vt-links__item {
      margin-bottom: var(--padding-y);

      &:last-child {
        margin-bottom: 0;
      }
    }

    .field-website {
      word-break: break-word;
    }

    .field-contact_info {
      .field__link {
        //display: block !important;
        //margin-bottom: .3rem;

        &::after {
          content: ".";
        }
      }
    }

    .svg-icon--eco {
      color: var(--color-text-grey);
      width: 1rem;
      height: 1rem;
    }
  }

  &__tr--archived  &__td {
    color: var(--color-text-grey);
  }

  &__tr:first-child &__td {
    border-top: 0;
  }


  a {
    text-decoration: none !important;
    color: currentColor;
  }

  .fld-contact-list--mails {
    display: none;
  }

  .column-min,
  .column-row-number {
    width: 10px;
  }
}

.print-details .print-project-categories {
  &.p-cat {
    display: block;
  }

  .p-cat__label {
    font-size: .8rem;
    line-height: .9rem;
    text-transform: uppercase;
    color: var(--color-text-main);
    margin-bottom: .5rem;
  }

  .p-cat__body {
    overflow: hidden;
  }

  .p-cat__list {
    margin-top: -1px;
    margin-bottom: 0;
    width: calc(50% - 1rem);
  }

  &.print-project-categories--columns .p-cat__list {
    width: auto;
    columns: 2 350px;
    column-gap: var(--col-gap);
  }

  .p-cat__item {
    margin: 0;
    padding: var(--info-item-padding-y) 0;
    border-top: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;

    &__icon {
      display: block;
      padding: 0 .3rem;
      width: 1.6rem;
      height: 1rem;
      margin-right: .4rem;

      .svg-icon {
        display: block;
        width: 1rem;
        height: 1rem;
      }
    }

    &__name {
      margin-right: .5rem;
    }
  }
}

.print-activities,
.print-notes {
  .an-section {
    margin-bottom: 1.6rem;

    &__title {
      font-weight: bold;
      font-size: .8rem;
      line-height: .9rem;
      border-bottom: 1px solid var(--line-color-main);
      padding-bottom: .6rem;
      text-transform: uppercase;
    }

    &__body {
      margin-top: -1px;
      columns: 2 350px;
      column-gap: var(--col-gap);
    }
  }

  .an-item {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    margin-bottom: .8rem;

    &__header {
      margin-bottom: .8rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &__title {
      display: flex;
      margin-bottom: .4rem;
      color: #808285;
      line-height: 1.1rem;
      font-size: .9rem;
      font-weight: bold;

      &__icon {
        width: 1.1rem;
        height: 1.1rem;
        margin-right: .4rem;

        .svg-icon {
          width: 100%;
          height: 100%;
        }
      }

      /*&__text {
        margin-top: .1rem;
      }*/
    }
  }

  .an-status-icon {
    width: .8rem;
    height: .8rem;
    line-height: .8rem;
    margin-right: .5rem;

    .svg-icon {
      width: 100%;
      height: 100%;
    }
  }

  .an-status-overdue {
    margin-right: .7rem;
    display: flex;
    align-items: center;

    &__badge {
      font-size: .7rem;
      line-height: 0.8rem;
      padding: .1rem .2rem;
      /* margin-top: -.2rem; */
      background: none;
      color: var(--color-text-grey);
      border: 1px solid var(--border-color);
    }

    .svg-icon--activity-overdue {
      color: currentColor;
    }
  }

  .an-author, .an-date {
    font-size: .7rem;
    line-height: .8rem;
    /*margin-top: .1rem;*/
  }

  .an-author {
    margin-right: .5rem;
    font-weight: 500;
  }

  .an-date {
    color: #58595B;

    b {
      font-weight: normal;
    }
  }
}

.print-activities {
  .an-item {
    padding-top: .8rem;
    border-top: 1px solid var(--line-color-main);
  }
}

.print-notes {
  columns: 2 350px;
  column-gap: var(--col-gap);

  .an-item__body {
    border-bottom: 1px solid var(--line-color-main);
    padding-bottom: .8rem;
  }
}
